import { ProductSpecs } from '@graphcommerce/magento-product'
import { ProductSpecsFragment } from '@graphcommerce/magento-product/components/ProductSpecs/ProductSpecs.gql'
import { RowProductFragment } from '../RowProduct.gql'
import { useEffect } from 'react'

type SpecsProps = RowProductFragment & ProductSpecsFragment

export function Specs(props: SpecsProps) {
  const { title, aggregations, carouselItem } = props

  const items = carouselItem.length > 0 ? carouselItem.map((item) => {
    const options_split = item?.description?.split('\n');
    return {
      __typename: 'Aggregation',
      attribute_code: item?.name ?? "",
      count: 1,
      label: item?.name ?? "",
      options: options_split?.map((option => {
        return (
          {
            __typename: 'AggregationOption',
            count: 1,
            label: option,
            value: option,
          }
        );
      }))
    }
  }) : [];

  if (!aggregations && items.length === 0) return null

  let mergedAggregations: any = [];

  if (aggregations && items.length > 0) {
    mergedAggregations = [...aggregations as any, ...items];
  } else if (aggregations && items.length === 0) {
    mergedAggregations = aggregations;
  } else if (!aggregations && items.length > 0) {
    mergedAggregations = items;
  }

  return <ProductSpecs title={title} aggregations={mergedAggregations} />
}
