import { useQuery } from '@graphcommerce/graphql'
import { ColumnOne } from '@graphcommerce/next-ui'
import PriceTable from '../../ChipLabCustom/Configurable/PriceTable'
import { RetrieveTierPricesDocument } from '../../ChipLabCustom/Configurable/graphql/RetrieveTierPrices.gql'
import { Typography } from '@mui/material'

export function RowQuantity(props) {
  const { sku } = props


  if (sku === "all") {
    const composite_data = useQuery(RetrieveTierPricesDocument, { variables: { SKU: "composite-poker-chips" } });
    const clay_data = useQuery(RetrieveTierPricesDocument, { variables: { SKU: "clay-poker-chips" } });
    return (
      <>
        <div>
          <br />
          <ColumnOne>
            <Typography sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }} variant='h3'>Composite Poker Chips</Typography>
            {!composite_data.loading && <PriceTable tier_data={composite_data} />}
          </ColumnOne>
        </div>
        <div>
          <br />
          <ColumnOne>
            <Typography sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }} variant='h3'>Clay Poker Chips</Typography>
            {!clay_data.loading && <PriceTable tier_data={clay_data} />}
          </ColumnOne>
        </div>
      </>
    );
  }

  const tier_data = useQuery(RetrieveTierPricesDocument, { variables: { SKU: sku } });

  return (
    <div>
      <br />
      <ColumnOne>
        {!tier_data.loading && <PriceTable tier_data={tier_data} />}
      </ColumnOne>
    </div>
  )
}
