import { RichText } from '@graphcommerce/graphcms-ui'
import { IconSvg, breakpointVal, iconStar, responsiveVal } from '@graphcommerce/next-ui'
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import PageLink from 'next/link'
import { HeroBanner } from '../../../ChipLabCustom/Media/HeroBanner'
import { RowHeroBannerFragment } from '.././RowHeroBanner.gql'
import { Fragment } from 'react'
import { NoImageBanner } from 'components/ChipLabCustom/Media/NoImageBanner'

export function NoImage(props: RowHeroBannerFragment & { aggregatedReviews?: any }) {
  const { copy, aggregatedReviews } = props

  const reviews = JSON.parse(aggregatedReviews?.GetReviews?.reviews ?? "{}");
  const total_rating = aggregatedReviews?.GetReviews?.total_rating;
  const { total_count } = reviews;

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const element = document.getElementById('reviews')
    e.preventDefault()
    if (!element) return

    window.scrollTo({
      top: element.offsetTop - 50,
      left: 0,
      behavior: 'smooth',
    })
  }

  const disabledStar = (
    <IconSvg
      src={iconStar}
      sx={(theme) => ({ stroke: 'none', fill: theme.palette.grey[300] })}
    />
  )

  const star = (
    <IconSvg
      src={iconStar}
      sx={{ stroke: 'none', fill: '#FFDA1C' }}
    />
  )

  const title = (
    <RichText
      {...copy}
      sxRenderer={{
        'heading-one': (theme) => ({
          margin: 0,
          [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacings.xs,
          },
          textAlign: 'left',
          maxWidth: '100%',
          ...breakpointVal('fontSize', 38, 42, theme.breakpoints.values),
          '& strong': (theme) => ({
            color: theme.palette.primary.main
          }),
        }),
      }}
    />
  );

  return (
    <>
      <NoImageBanner
        title1={title}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginY: 'auto',
            maxWidth: 531,
            [theme.breakpoints.up('md')]: {
              ml: 4,
              mb: 2,
              padding: 1
            },
          })}
        >
          <Typography
            onClick={handleClick}
            sx={(theme) => ({
              display: 'flex',
              cursor: 'pointer',
            })}>
            <Box
              sx={[
                {
                  width: 'max-content',
                  marginRight: 2,
                  position: 'relative',
                  top: 3,
                  '& > div': { whiteSpace: 'nowrap' },
                },
              ]}
            >
              <div>
                {disabledStar}
                {disabledStar}
                {disabledStar}
                {disabledStar}
                {disabledStar}
              </div>
              <Box
                style={{ width: `${total_rating}%` }}
                sx={{
                  position: 'absolute',
                  top: 0,
                  overflow: 'hidden',
                  '& > div': { whiteSpace: 'nowrap' },
                }}
              >
                <div>
                  {star}
                  {star}
                  {star}
                  {star}
                  {star}
                </div>
              </Box>
            </Box>
            <Typography sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                fontSize: '14px'
              }
            })}>
              <strong>{((total_rating ?? 0) / 20).toFixed(1).replace(/[.,]0$/, "")}</strong> out of 5 based on <strong>{(total_count ?? 0).toLocaleString()} reviews</strong>
            </Typography>
          </Typography>
        </Box>
      </NoImageBanner >
    </>
  )
}
