import type { Maybe } from '@graphcommerce/graphql-mesh'
import { RenderType } from '@graphcommerce/next-ui'
import { useState } from 'react'
import { ImageSwatchData } from '@graphcommerce/magento-product-configurable/Swatches/ImageSwatchData'
import { SwatchSize, SwatchTypeRenderer } from '@graphcommerce/magento-product-configurable/Swatches/types'
import { Box } from '@mui/system'
import { ColorSwatchData } from '../TemplateItems/ColorSwatchData'
import { TextSwatchData } from '../Media/TextSwatchData'
import { ProductListItemConfigurableFragment, Selected, useConfigurableContext } from '@graphcommerce/magento-product-configurable'
import { ToggleButtonColorGroup } from '../Configurable/ToggleButtonColorGroup'
import { ToggleColorButton } from '../Configurable/ToggleColorButton'
import { Controller, UseControllerProps } from '@graphcommerce/react-hook-form'
import { useFormGqlMutationCart } from '@graphcommerce/magento-cart'
import { ConfigurableProductAddToCartDocument } from '@graphcommerce/magento-product-configurable/graphql/ConfigurableProductAddToCart.gql'
import { ToggleButton } from '../Media/ToggleButton'
import { ToggleButtonGroup } from '../Media/ToggleButtonGroup'

type SwatchListProps = {
  attributes: string[]
  configurable_options: Maybe<ProductListItemConfigurableFragment['configurable_options']>
  template?: any
  setTemplate?: any
  setError?: any
  item?: any
  expanded?: boolean
  setExpanded?: any
} & UseControllerProps<any>

const renderer: SwatchTypeRenderer = {
  TextSwatchData,
  ImageSwatchData,
  ColorSwatchData,
}

export function ProductSwatchList({ attributes, configurable_options, template, setTemplate, setError, item, name, expanded, setExpanded }: SwatchListProps) {
  const options =
    configurable_options?.filter((option) => attributes.includes(option?.attribute_code ?? '')) ??
    []

  const leftover = (options[0]?.values?.length) as any - 4;
  const { selection, select, getVariants, getUids } = useConfigurableContext(item?.sku)
  const [currentColor, setCurrentColor] = useState("None");

  const form = useFormGqlMutationCart(ConfigurableProductAddToCartDocument, {
    defaultValues: { sku: item.sku ?? '', quantity: 1 },
    onBeforeSubmit: ({ selectedOptions, ...vars }) => ({
      ...vars,
      // todo: selectedOptions should contain the correct values directly
      selectedOptions: getUids(selectedOptions?.[0] as unknown as Selected),
    }),
  })

  const { control } = form
  return (
    <>
      {options?.map((option) => {
        if (!option?.uid || !option.attribute_code) return null

        const { attribute_code } = option

        if (attribute_code === "color") {
          return (
            <Controller
              key={option.uid}
              defaultValue={selection[attribute_code] ?? ''}
              name={`${name}[${attribute_code}]` as any}
              control={control}
              render={({
                field: { onChange, value, name: inputName, ref, onBlur },
              }) => (
                <>
                  <ToggleButtonColorGroup
                    defaultValue={selection[attribute_code] ?? ''}
                    required
                    exclusive
                    onChange={(_, val: string | number) => {
                      onChange(val)
                      select((prev) => ({ ...prev, [attribute_code]: val } as Selected))
                    }}
                    ref={ref}
                    onBlur={onBlur}
                    value={value}
                    size={'large'}
                  >
                    {expanded && option?.values?.map((val) => {
                      if (!val?.uid || !option.attribute_code) return null

                      // Fall back to text swatch if no swatch is given
                      const swatch_data = val.swatch_data ?? {
                        __typename: 'TextSwatchData',
                        value: val.store_label,
                      }

                      const copySelection = { ...selection }
                      delete copySelection[attribute_code]

                      const itemVariant = getVariants(copySelection).find((variant) =>
                        variant?.attributes?.find((attribute) => attribute?.uid === val.uid),
                      )

                      return (
                        <ToggleColorButton
                          key={val.uid}
                          value={val.uid ?? ''}
                          val={val.store_label ?? ''}
                          name={inputName}
                          disabled={!itemVariant}
                          sx={{
                            bgcolor: 'transparent'
                          }}
                          size={'large'}
                          setCurrentColor={setCurrentColor}
                        >
                          <RenderType
                            key={val?.uid ?? ''}
                            renderer={renderer}
                            {...val}
                            {...swatch_data}
                            template={template}
                            setTemplate={setTemplate}
                            setError={setError}
                            size={'large' as SwatchSize}
                          />
                        </ToggleColorButton>
                      )
                    })}
                    {!expanded && option?.values?.map((val, idx) => {
                      if (val?.swatch_data && idx < 4) {
                        if (!val?.uid || !option.attribute_code) return null

                        // Fall back to text swatch if no swatch is given
                        const swatch_data = val.swatch_data ?? {
                          __typename: 'TextSwatchData',
                          value: val.store_label,
                        }

                        const copySelection = { ...selection }
                        delete copySelection[attribute_code]

                        const itemVariant = getVariants(copySelection).find((variant) =>
                          variant?.attributes?.find((attribute) => attribute?.uid === val.uid),
                        )

                        return (
                          <ToggleColorButton
                            key={val.uid}
                            value={val.uid ?? ''}
                            val={val.store_label ?? ''}
                            name={inputName}
                            disabled={!itemVariant}
                            size={'large'}
                            setCurrentColor={setCurrentColor}
                            sx={{
                              bgcolor: 'transparent'
                            }}
                          >
                            <RenderType
                              key={val?.uid ?? ''}
                              renderer={renderer}
                              {...val}
                              {...swatch_data}
                              template={template}
                              setTemplate={setTemplate}
                              setError={setError}
                              size={'large' as SwatchSize}
                            />
                          </ToggleColorButton>
                        )
                      }
                    })}
                  </ToggleButtonColorGroup>
                </>
              )}
            />
          )
        }

        return (
          <Controller
            key={option.uid}
            defaultValue={selection[attribute_code] ?? ''}
            name={`${name}[${attribute_code}]` as any}
            control={control}
            render={({
              field: { onChange, value, name: inputName, ref, onBlur },
            }) => (
              <>
                <ToggleButtonGroup
                  defaultValue={selection[attribute_code] ?? ''}
                  required
                  exclusive
                  onChange={(_, val: string | number) => {
                    onChange(val)
                    select((prev) => ({ ...prev, [attribute_code]: val } as Selected))
                  }}
                  ref={ref}
                  sx={{
                    bgcolor: 'transparent'
                  }}
                  onBlur={onBlur}
                  value={value}
                  size={'large'}
                >
                  {option?.values?.map((val) => {
                    if (!val?.uid || !option.attribute_code) return null

                    // Fall back to text swatch if no swatch is given
                    const swatch_data = val.swatch_data ?? {
                      __typename: 'TextSwatchData',
                      value: val.store_label,
                    }

                    const copySelection = { ...selection }
                    delete copySelection[attribute_code]

                    const itemVariant = getVariants(copySelection).find((variant) =>
                      variant?.attributes?.find((attribute) => attribute?.uid === val.uid),
                    )

                    return (
                      <ToggleButton
                        key={val.uid}
                        value={val.uid ?? ''}
                        name={inputName}
                        disabled={!itemVariant}
                        sx={{
                          bgcolor: 'transparent',
                          border: 0,
                        }}
                        size={'large'}
                      >
                        <RenderType
                          key={val?.uid ?? ''}
                          renderer={renderer}
                          {...val}
                          {...swatch_data}
                          template={template}
                          setTemplate={setTemplate}
                          setError={setError}
                          size={'large' as SwatchSize}
                        />
                      </ToggleButton>
                    )
                  })}
                </ToggleButtonGroup>
              </>
            )}
          />
        )
      })}
      {!expanded && attributes.includes('color') &&
        <Box onClick={(e) => { e.preventDefault(); setExpanded(true) }} sx={(theme) => ({ color: theme.palette.mode === "dark" ? "white" : "black", textDecoration: 'none', marginTop: 1.5, cursor: 'pointer' })}>
          +{leftover}
        </Box>
      }
    </>
  )
}
