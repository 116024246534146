import { ProductListItemProps } from '@graphcommerce/magento-product'
import { ProductListItemDownloadableFragment } from '@graphcommerce/magento-product-downloadable/ProductListItemDownloadable.gql'
import { ProductListCategoryItem } from './ProductListCategoryItem'

export type ProductListItemDownloadableProps = ProductListItemDownloadableFragment &
  ProductListItemProps

export function ProductListItemDownloadable(props: ProductListItemDownloadableProps) {
  return <ProductListCategoryItem {...props} />
}
