import { Box, Typography, Card, CardContent, CardActions, Button } from "@mui/material";
import Link from "next/link";
import { Image } from '@graphcommerce/image'

function RowCategory(props) {
  const { item, title, pageLinks } = props;

  const lower = item.name.toLowerCase();
  const sku = lower.replaceAll(" ", "-");

  return (
    <>
      <Link href={`/p/${sku}`} passHref style={{ textDecoration: 'none' }}>
        {/* <Card sx={(theme) => ({ maxWidth: '100%' })}>
          <Box
            sx={{
              maxHeight: '100%', overflow: 'hidden'
            }}>
            <Image
              layout='fill'
              sx={{ objectFit: 'contain' }}
              src={item?.image !== "" ? item.image as string : '/butch/static/version1673973159/adminhtml/Magento/backend/en_US/Magento_Catalog/images/product/placeholder/thumbnail.jpg' as string}
              title={item?.name as string}
            />
          </Box>
          <CardContent>
            <Typography gutterBottom variant="h3" component="div">
              {item?.name}
            </Typography>
            <Typography variant="body1" color="text.primary" sx={{ overflowWrap: 'break-word' }}>
              {item?.description}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" variant='text' color='primary'>Shop {item?.name} {'>'}</Button>
          </CardActions>
        </Card> */}
      </Link>
    </>
  );
}

export default RowCategory;