import { Image, ImageProps } from '@graphcommerce/image'
import { responsiveVal, extendableComponent, useNumberFormat } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { ButtonBase, Typography, Box, styled, SxProps, Theme } from '@mui/material'
import Link from 'next/link'
import React, { PropsWithChildren } from 'react'
import { ProductListItemFragment } from '@graphcommerce/magento-product/Api/ProductListItem.gql'
import { useProductLink } from '@graphcommerce/magento-product/hooks/useProductLink'
import { ProductListPrice } from '@graphcommerce/magento-product/components/ProductListPrice/ProductListPrice'

const { classes, selectors } = extendableComponent('ProductListItem', [
  'root',
  'item',
  'title',
  'titleContainer',
  'subtitle',
  'price',
  'overlayItems',
  'topLeft',
  'topRight',
  'bottomLeft',
  'bottomRight',
  'imageContainer',
  'placeholder',
  'image',
  'discount',
] as const)

export type OverlayAreaKeys = 'topLeft' | 'bottomLeft' | 'topRight' | 'bottomRight'

export type OverlayAreas = Partial<Record<OverlayAreaKeys, React.ReactNode>>

type StyleProps = {
  aspectRatio?: [number, number]
  imageOnly?: boolean
}

type BaseProps = PropsWithChildren<
  { subTitle?: React.ReactNode } & StyleProps &
  OverlayAreas &
  ProductListItemFragment &
  Pick<ImageProps, 'loading' | 'sizes' | 'dontReportWronglySizedImages'>
>

export type ProductListItemProps = BaseProps & {
  sx?: SxProps<Theme>
  titleComponent?: React.ElementType
}

const StyledImage = styled(Image)({})

export function ProductListCategoryItem(props: ProductListItemProps) {
  const {
    subTitle,
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    small_image,
    name,
    price_range,
    children,
    imageOnly = false,
    loading,
    sizes,
    dontReportWronglySizedImages,
    titleComponent = 'h2',
    sx = [],
  } = props

  let url: any = "";

  if (small_image?.url === "") {
    url = "/butch/static/version1673973159/adminhtml/Magento/backend/en_US/Magento_Catalog/images/product/placeholder/thumbnail.jpg";
  } else {
    url = small_image?.url;
  }

  const productLink = useProductLink(props)
  const discount = Math.floor(price_range.minimum_price.discount?.percent_off ?? 0)

  const formatter = useNumberFormat({ style: 'percent', maximumFractionDigits: 1 })

  return (
    <Link href={productLink} passHref style={{ textDecoration: 'none' }}>
      <ButtonBase
        component='a'
        sx={[
          (theme) => ({
            display: 'block',
            position: 'relative',
            height: '100%',
            borderRadius: responsiveVal(theme.shape.borderRadius * 2, theme.shape.borderRadius * 3),
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        className={classes.root}
      >
        <Box
          sx={(theme) => ({
            display: 'grid',
            bgcolor: 'background.image',
            borderRadius: '8px',
            padding: responsiveVal(8, 12),
            '& > picture': {
              gridArea: `1 / 1 / 3 / 3`,
              margin: `calc(${responsiveVal(8, 12)} * -1)`,
            },
          })}
          className={classes.imageContainer}
        >
          {small_image ? (
            <StyledImage
              layout='fill'
              width={1}
              height={1}
              sizes={sizes}
              dontReportWronglySizedImages={dontReportWronglySizedImages}
              src={url ?? ''}
              alt={small_image.label ?? ''}
              className={classes.image}
              loading={loading}
              sx={{ objectFit: 'contain', borderRadius: '8px' }}
            />
          ) : (
            <Box
              sx={{
                gridArea: `1 / 1 / 3 / 3`,
                typography: 'caption',
                display: 'flex',
                textAlign: 'center',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'background.default',
                userSelect: 'none',
              }}
              className={`${classes.placeholder} ${classes.image}`}
            >
              <Trans id='No Image' />
            </Box>
          )}

          {!imageOnly && (
            <>
              <Box
                sx={{
                  gridArea: `1 / 1 / 2 / 2`,
                  zIndex: 1,
                }}
                className={classes.topLeft}
              >
                {discount > 0 && (
                  <Box
                    className={classes.discount}
                    sx={{
                      typography: 'caption',
                      bgcolor: 'text.primary',
                      fontWeight: 'fontWeightBold',
                      border: 1,
                      borderColor: 'divider',
                      padding: '0px 6px',
                      color: 'background.default',
                      display: 'inline-block',
                    }}
                  >
                    {formatter.format(discount / -100)}
                  </Box>
                )}
                {topLeft}
              </Box>
              <Box
                sx={{
                  justifySelf: 'end',
                  textAlign: 'right',
                  gridArea: `1 / 2 / 2 / 3`,
                  zIndex: 1,
                }}
                className={classes.topLeft}
              >
                {topRight}
              </Box>
              <Box
                sx={{
                  alignSelf: 'flex-end',
                  gridArea: `2 / 1 / 3 / 2`,
                  zIndex: 1,
                }}
                className={classes.bottomLeft}
              >
                {bottomLeft}
              </Box>
              <Box
                sx={{
                  textAlign: 'right',
                  alignSelf: 'flex-end',
                  gridArea: `2 / 2 / 3 / 3`,
                  zIndex: 1,
                  justifySelf: 'end',
                }}
                className={classes.bottomRight}
              >
                {bottomRight}
              </Box>
            </>
          )}
        </Box>

        {!imageOnly && (
          <>
            <Box
              className={classes.titleContainer}
              sx={(theme) => ({
                display: 'grid',
                alignItems: 'baseline',
                marginTop: theme.spacings.xs,
                columnGap: 1,
                gridTemplateAreas: {
                  xs: `"title title" "subtitle price"`,
                  md: `"title subtitle price"`,
                },
                gridTemplateColumns: { xs: 'unset', md: 'auto auto 1fr' },
                justifyContent: 'space-between',
              })}
            >
              <Typography
                component={titleComponent}
                variant='subtitle1'
                sx={{
                  display: 'inline',
                  color: 'text.primary',
                  overflowWrap: 'break-word',
                  wordBreak: 'break-word',
                  maxWidth: '100%',
                  gridArea: 'title',
                  fontWeight: 'fontWeightBold',
                }}
                className={classes.title}
              >
                {name}
              </Typography>
              <Box sx={{ gridArea: 'subtitle' }} className={classes.subtitle}>
                {subTitle}
              </Box>

              <ProductListPrice
                {...price_range.minimum_price}
                sx={(theme) => ({
                  gridArea: 'price',
                  textAlign: 'right',
                  justifySelf: { sm: 'flex-end' },
                  color: theme.palette.text.primary,
                  textDecoration: 'none'
                })}
              />
            </Box>
            {children}
          </>
        )}
      </ButtonBase>
    </Link>
  )
}

ProductListCategoryItem.selectors = { ...selectors, ...ProductListPrice.selectors }
