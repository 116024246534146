import { iconCustomerService, iconHeart, IconSvg, responsiveVal } from '@graphcommerce/next-ui'
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import PageLink from 'next/link'
import Link from 'next/link'
import { Link as MuiLink } from '@mui/material'
import { Image } from '@graphcommerce/image'
import { ContainerWithHeader } from '../../../ChipLabCustom/Media/ContainerWithHeader'
import { useLazyQuery } from '@graphcommerce/graphql'
import { InstagramPostsDocument } from 'graphql/InstagramPosts.gql'
import { useEffect, useState } from 'react'

export function SocialBlock(props) {
  const { title, pageLinks, getInstagramPosts, asset, uid, sx = [] } = props

  const [posts, setPosts] = useState(getInstagramPosts);
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();

  const titleWithIcon = (
    <>
      <Link href={'https://www.instagram.com/chiplab/'} target='_blank'>
        <img style={{
          marginRight: 4,
          height: 25,
          width: 26,
          filter: theme.palette.mode === 'dark' ? 'invert(100%)' : 'none',
        }} src={asset?.url} alt={asset?.alt ?? asset?.url} />
      </Link>
      {title}
    </>
  );
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const big = useMediaQuery(theme.breakpoints.up('md'));
  const [fetchPosts, { data, loading, called }] = useLazyQuery(InstagramPostsDocument, { variables: { page: 1, limit: 10, sortField: 4 } })

  useEffect(() => {
    if (xs) {
      fetchPosts({ variables: { page: 1, limit: 2, sortField: 4 } })
      setExpanded(false);
    } else if (sm) {
      fetchPosts({ variables: { page: 1, limit: 4, sortField: 4 } })
      setExpanded(false);
    } else if (big) {
      fetchPosts({ variables: { page: 1, limit: 10, sortField: 4 } })
      setExpanded(false);
    }
  }, [xs, sm, big])

  useEffect(() => {
    if (called) {
      if (!loading) {
        setPosts(data?.getInstagramPosts)
      }
    }
  }, [data, loading, called]);

  return (
    <ContainerWithHeader
      title={titleWithIcon}
      centered
      sx={{
        maxWidth: "lg",
        overflow: 'hidden'
      }}
      rightArea={pageLinks.map((pageLink) => (
        <PageLink href={pageLink?.url} key={pageLink?.url} passHref>
          <MuiLink color='inherit' href={pageLink?.url} underline='always'>
            {pageLink?.title}
          </MuiLink>
        </PageLink>
      ))}
    >
      <Box
        sx={[
          (theme) => ({
            display: 'grid',
            gridTemplateColumns: { xs: `repeat(1, minmax(auto, 1fr))`, sm: `repeat(2, minmax(auto, 1fr))`, md: `repeat(5, minmax(auto, 1fr))` },
            gridAutoRows: { xs: `1fr`, md: responsiveVal(0, 285) },
            columnGap: theme.spacings.xxs,
            rowGap: theme.spacings.xxs
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {posts?.map((item, idx) =>
          item ? (
            <Link key={item?.permalink} href={item?.permalink as string} legacyBehavior passHref style={{ textDecoration: 'none' }}>
              <a target="_blank" style={{ color: 'inherit' }} >
                <Box
                  sx={{
                    overflow: 'hidden',
                    position: 'relative',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '100%',
                    height: '100%',
                  }}>
                  <Box
                    sx={(theme) => ({
                      position: 'absolute',
                      zIndex: 1,
                      opacity: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: theme.palette.info.contrastText,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      transition: '0.3s',
                      "&:hover": {
                        opacity: 0.8,
                      }
                    })}>
                    <Box
                      sx={{
                        position: 'relative',
                        overflow: 'scroll',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        {parseInt(item?.like_count as string) > 0 && <Box
                          sx={{
                            padding: 2
                          }}>
                          <IconSvg src={iconHeart} /> {item?.like_count}
                        </Box>}
                        {parseInt(item?.comments_count as string) > 0 && <Box
                          sx={{
                            padding: 2
                          }}>
                          <IconSvg src={iconCustomerService} /> {item?.comments_count}
                        </Box>}
                      </Box>
                      <Typography sx={{
                        textAlign: 'center',
                        padding: 1,
                        overflowWrap: 'break-word',
                      }}>{item?.caption}</Typography>
                    </Box>
                  </Box>
                  <Image
                    layout='fill'
                    sx={{
                      borderRadius: '8px',
                      objectFit: 'cover',
                      objectPosition: 'center'
                    }}
                    alt={item?.media_url}
                    src={`${item?.media_url}` as string}
                  />
                </Box>
              </a>
            </Link>
          ) : null,
        )}
      </Box>
      <Box sx={{
        marginTop: 3,
        justifyContent: 'center',
        display: 'flex'
      }}>
        {!expanded && <Button
          variant='outlined'
          color='primary'
          sx={{
            borderRadius: '50px'
          }}
          onClick={() => {
            setExpanded(true);
            if (xs) {
              fetchPosts({ variables: { page: 1, limit: 4, sortField: 4 } })
            } else if (sm) {
              fetchPosts({ variables: { page: 1, limit: 8, sortField: 4 } })
            } else if (big) {
              fetchPosts({ variables: { page: 1, limit: 20, sortField: 4 } })
            }
          }}
        >Load More Instagram Posts</Button>}
      </Box>
    </ContainerWithHeader >
  )
}
