import { Image, ImageProps } from '@graphcommerce/image'
import { styled, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material'
import { AssetFragment } from '@graphcommerce/graphcms-ui/components/Asset/Asset.gql'
import { useEffect, useRef } from 'react'

export type { AssetFragment } from '@graphcommerce/graphcms-ui/components/Asset/Asset.gql'

type ImageAsset = Omit<AssetFragment, 'width' | 'height'> & {
  width: number
  height: number
}

function isImage(asset: AssetFragment): asset is ImageAsset {
  return !!(asset.width && asset.height)
}

type AssetProps = {
  asset: AssetFragment
  sx?: SxProps<Theme>
  timeStamp?: any
} & Omit<ImageProps, 'src' | 'width' | 'height' | 'alt' | 'sx'>

export function Asset(props: AssetProps) {
  const { asset, timeStamp, sx = [], ...imgProps } = props

  const vidRef = useRef() as any;
  const theme = useTheme();
  const inMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (vidRef.current) {
      if (!inMobile) {
        vidRef.current.currentTime = Number(timeStamp) ?? 0;
      }
    }
  }, [vidRef])

  function playVideo() {
    if (!inMobile) {
      vidRef.current.play();
    }
  }

  function pauseVideo() {
    if (!inMobile) {
      vidRef.current.pause();
      vidRef.current.currentTime = Number(timeStamp ?? 0);
    }
  }

  if (isImage(asset)) {
    const { url, height, mimeType, size, width, alt, ...assetProps } = asset
    return (
      <Image
        src={url}
        height={height}
        width={width}
        alt={alt ?? "By Use"}
        {...imgProps}
        {...assetProps}
        unoptimized={mimeType === 'image/svg+xml'}
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
      />
    )
  }

  if (asset.mimeType === 'video/mp4') {
    const Video = styled('video')({})

    return (
      <Video
        src={asset.url}
        ref={vidRef}
        autoPlay={inMobile}
        muted
        loop
        onMouseOver={playVideo}
        onMouseOut={pauseVideo}
        playsInline
        disableRemotePlayback
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
      />
    )
  }

  if (process.env.NODE_ENV !== 'production') return <div>{asset.mimeType} not supported</div>
  return null
}
