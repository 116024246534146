import Link from "next/link";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { IconSvg, iconStar, responsiveVal, useColorMode } from "@graphcommerce/next-ui";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "@graphcommerce/graphql";
import { Selected, useConfigurableContext } from "@graphcommerce/magento-product-configurable";
import { AttributeTetheringDocument } from "../../GraphCMS/AttributeTether/AttributeTether.gql";
import ProductContext from "../ProductContext/product-context";
import { ProductSwatchList } from "../Media/ProductSwatchList";
import Image from "next/image";

export default function GridItem(props) {
  const { item, typeProduct, size = 'normal' } = props;
  const [error, setError] = useState('');
  const [noPointer, setNoPointer] = useState(false);
  // const { currentMode } = useColorMode();
  const theme = useTheme();
  const inMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const element = document.getElementById('reviews')
    e.preventDefault()
    if (!element) {
      setNoPointer(true);
      return;
    }

    window.scrollTo({
      top: element.offsetTop - 50,
      left: 0,
      behavior: 'smooth',
    })
  }

  const disabledStar = (
    <IconSvg
      src={iconStar}
      sx={(theme) => ({ stroke: 'none', fill: theme.palette.grey[300] })}
    />
  )

  const star = (
    <IconSvg
      src={iconStar}
      sx={{ stroke: 'none', fill: '#FFDA1C' }}
    />
  )
  const [expanded, setExpanded] = useState(false);
  const { changeFilters } = useContext(ProductContext);
  const [currentProduct, setCurrentProduct] = useState(item) as any;
  const [hasOne, setHasOne] = useState() as any;
  const { getVariants, selection, select } = useConfigurableContext(item?.sku)

  const overrides = useQuery(AttributeTetheringDocument, { variables: { url: 'product/global' } });
  let override_data: any = overrides?.data?.pages[0]?.content;
  const variants: any = getVariants(selection);
  const attributes = variants[0]?.attributes;
  const [overridesArray, setOverridesArray] = useState([]) as any[];
  override_data = override_data?.filter((content) => (content?.__typename === "AttributeTether"));
  override_data = override_data?.filter((content) => (content?.sku === item.sku));

  useEffect(() => {
    if (overrides.loading) {
      return;
    }
    if (variants) {
      for (let variant of variants) { //Iterate through all possible variants of selection.
        if (variant?.attributes) {
          for (let attribute of variant.attributes) { //Iterate through every attribute of the variant.
            for (let content of override_data) { //Iterate through the CMS map.
              if (attribute && attribute?.code) {
                if ((attribute.code === content.attributeName && attribute.uid === content.uid)) {//Match the code to the CMS attribute name and the uid to the CMS uid.
                  for (let attribute2 of variant.attributes) { //Iterate through every other attribute per the variants.
                    if (attribute2?.code) {
                      if (attribute2.code === content.connectedName && attribute2.uid === content.connectedUid) {//Match the connectedName to the other attribute's code and the connectedUid to the other attribute's UID.
                        setOverridesArray((prev: any) => [...prev, { [attribute.code]: attribute.uid, [attribute2.code]: attribute2.uid }]);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }, []); //This code is executed during static generation

  useEffect(() => {
    if (Object.keys(selection).find(key => key === "edge_pattern") && !Object.keys(selection).find(key => key === "color")) {
      select((prev) => (findOtherAttribute(prev, 'color', 'edge_pattern') as Selected | any));
      setHasOne((prev) => !prev)
    } else if (!Object.keys(selection).find(key => key === "edge_pattern") && Object.keys(selection).find(key => key === "color")) {
      select((prev) => (findOtherAttribute(prev, 'edge_pattern', 'color') as Selected | any));
      setHasOne((prev) => !prev)
    }

    if (Object.keys(selection).length == 0) {
      setHasOne((prev) => !prev)
    } else {
      setCurrentProduct({ ...getVariants(selection)[0]?.product });
      setHasOne((prev) => !prev)
    }

  }, [selection, overrides]);

  function worryAboutExpansion(override, code) {
    const option = item?.configurable_options?.find((i) => i.attribute_code === code);
    if (option) {
      const found = option?.values?.findIndex((i) => i.uid === override[code]);
      if (found !== -1) {
        if (found > 3) {
          setExpanded(true);
        }
      }
    }
  }

  function findOtherAttribute(prev, code, current) {
    let att = {};
    if (overridesArray.length > 0) {
      for (let override of overridesArray) {
        if (override && override[current] === selection[Object.keys(selection)[0]]) {
          changeFilters({ ...prev, [code]: override[code] });
          worryAboutExpansion(override, code);
          return ({ ...prev, [code]: override[code] })
        }
      }
    }
    if (attributes) {
      for (let attribute of attributes) {
        if (attribute) {
          if (attribute.code === code && attribute.code) {
            att = { [attribute.code]: attribute.uid };
          }
        }
      }
    }
    changeFilters({ ...prev, ...att });
    worryAboutExpansion(att, code);
    return { ...prev, ...att };
  }

  return (
    <Box>
      <Box sx={{
        height: '100%',
        minWidth: '100%'
      }}>
        <Link href={`/p/${item.url_key}`} passHref style={{ textDecoration: 'none' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              justifyContent: 'flex-end',
              alignItems: 'center',
              overflow: 'hidden',
              margin: 'auto',
              [theme.breakpoints.down(400)]: {
                height: '350px',
              },
              borderRadius: '8px',
              paddingBottom: theme.spacings.xs,
              position: 'relative',
              aspectRatio: '1/1',
            }}>
            <Image
              fill
              src={currentProduct?.small_image?.url !== "" ? currentProduct?.small_image?.url : item?.small_image?.url}
              alt={item?.name}
              style={{
                objectPosition: 'center',
                objectFit: 'cover'
              }}
            />
            <Button variant='pill' color='primary' sx={{
              zIndex: 2,
              position: 'absolute'
            }}
              onClick={() => {
                changeFilters(selection);
              }}>Customize</Button>
          </Box>
        </Link>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: theme.spacings.xs
          })}>
          <Typography gutterBottom variant="h3" component="div" sx={(theme) => ({ color: theme.palette.text.primary })}>
            {item?.name}
          </Typography>
          <Typography gutterBottom component="div" sx={(theme) => ({ color: theme.palette.text.primary })}>
            {item?.price_tiers?.[3]?.quantity} chips for <strong>${(item?.price_tiers?.[3]?.final_price?.value * item?.price_tiers?.[3]?.quantity).toFixed(2).replace(/[.,]00$/, "")}</strong>
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
          }}>
          <Typography
            onClick={handleClick}
            sx={[
              {
                width: 'max-content',
                marginRight: 2,
                position: 'relative',
                top: 3,
              },
              !noPointer && {
                cursor: 'pointer'
              }
            ]}
          >
            <div>
              {disabledStar}
              {disabledStar}
              {disabledStar}
              {disabledStar}
              {disabledStar}
            </div>
            <Box
              style={{ width: `${item.rating_summary}%` }}
              sx={{
                position: 'absolute',
                top: 0,
                overflow: 'hidden',
                '& > div': { whiteSpace: 'nowrap' },
              }}
            >
              <div>
                {star}
                {star}
                {star}
                {star}
                {star}
              </div>
            </Box>
          </Typography>
          <Typography sx={(theme) => ({ color: theme.palette.text.primary })}>
            {item?.review_count.toLocaleString() ?? 0} reviews
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
          })}>
          <Box dangerouslySetInnerHTML={{ __html: item?.short_description?.html }} component="div"
            sx={(theme) => ({
              fontSize: '15px',
              // color: currentMode === "dark" ? theme.palette.secondary.contrastText : theme.palette.secondary.main
              color: theme.palette.secondary.main
            })}>
          </Box>
        </Box>
        {
          !inMobile && typeProduct &&
          <>
            <Box
              onClick={(e) => { e.preventDefault(); }}
              sx={(theme) =>
              ({
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                rowGap: theme.spacings.xs,
                marginTop: 2
              })}>
              {hasOne && <ProductSwatchList
                name="selectedOptions"
                attributes={['edge_pattern']}
                expanded={expanded}
                setExpanded={setExpanded}
                configurable_options={item?.configurable_options}
                setError={setError}
                item={item}
              />}
              {!hasOne && <ProductSwatchList
                name="selectedOptions"
                attributes={['edge_pattern']}
                expanded={expanded}
                setExpanded={setExpanded}
                configurable_options={item?.configurable_options}
                setError={setError}
                item={item}
              />}
            </Box>
            <Box
              onClick={(e) => { e.preventDefault(); }}
              sx={(theme) =>
              ({
                display: 'flex',
                flexWrap: 'wrap',
                columnGap: theme.spacings.xs,
                rowGap: theme.spacings.xs,
                marginTop: 2
              })}>

              {hasOne && <ProductSwatchList
                name="selectedOptions"
                attributes={['color']}
                expanded={expanded}
                setExpanded={setExpanded}
                configurable_options={item?.configurable_options}
                setError={setError}
                item={item}
              />}
              {!hasOne && <ProductSwatchList
                name="selectedOptions"
                attributes={['color']}
                expanded={expanded}
                setExpanded={setExpanded}
                configurable_options={item?.configurable_options}
                setError={setError}
                item={item}
              />}
            </Box>
          </>
        }
        <Box sx={{ color: 'red' }}>
          {error}
        </Box>
      </Box >
    </Box >
  );
}