/* eslint-disable */
import * as Types from '@graphcommerce/graphql-mesh/.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export const RetrieveTierPricesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RetrieveTierPrices"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"SKU"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"products"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"sku"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"eq"},"value":{"kind":"Variable","name":{"kind":"Name","value":"SKU"}}}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"price_tiers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"quantity"}},{"kind":"Field","name":{"kind":"Name","value":"final_price"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<RetrieveTierPricesQuery, RetrieveTierPricesQueryVariables>;
export type RetrieveTierPricesQueryVariables = Types.Exact<{
  SKU: Types.Scalars['String'];
}>;


export type RetrieveTierPricesQuery = { products?: { items?: Array<{ uid: string, id?: number | null, price_tiers?: Array<{ quantity?: number | null, final_price?: { value?: number | null } | null } | null> | null } | { uid: string, id?: number | null, price_tiers?: Array<{ quantity?: number | null, final_price?: { value?: number | null } | null } | null> | null } | { uid: string, id?: number | null, price_tiers?: Array<{ quantity?: number | null, final_price?: { value?: number | null } | null } | null> | null } | { uid: string, id?: number | null, price_tiers?: Array<{ quantity?: number | null, final_price?: { value?: number | null } | null } | null> | null } | { uid: string, id?: number | null, price_tiers?: Array<{ quantity?: number | null, final_price?: { value?: number | null } | null } | null> | null } | { uid: string, id?: number | null, price_tiers?: Array<{ quantity?: number | null, final_price?: { value?: number | null } | null } | null> | null } | null> | null } | null };
