import {
  Scroller,
  ScrollerButtonProps as ScrollerButtonPropsType,
  useScrollerContext,
  useScrollTo,
} from '@graphcommerce/framer-scroller'
import { Box, SxProps, Theme } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import { Row } from '@graphcommerce/next-ui/Row/Row'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { responsiveVal } from '@graphcommerce/next-ui/Styles/responsiveVal'
import ReviewCard from '../Reviews/ReviewCard'

const { classes, selectors } = extendableComponent('SidebarSlider', [
  'root',
  'grid',
  'sidebar',
  'scrollerContainer',
  'scroller',
  'sliderButtons',
  'centerLeft',
  'centerRight',
] as const)

export type SidebarSliderProps = {
  children: ReactNode
  sidebar: ReactNode
  sx?: SxProps<Theme>
  buttonSize?: ScrollerButtonPropsType['size']
  items?: any
}

export function TopbarSlider(props: SidebarSliderProps) {
  const { children, sidebar, sx, buttonSize, items } = props

  const { getSnapPosition, getScrollSnapPositions, scrollerRef, } = useScrollerContext();
  const scrollTo = useScrollTo();
  const [index, setIndex] = useState(0);

  function handleClick() {
    if (index === items.length - 1) {
      scrollTo({ x: 0, y: 0 });
      setIndex(0);
    } else {
      setIndex(index + 1);
      scrollTo(getSnapPosition('right'));
    }
  }


  useEffect(() => {
    const id = setInterval(async () => {
      handleClick();
    }, 3000);
    return () => {
      clearInterval(id);
    }
  })


  return (
    <Row maxWidth={'xl'} disableGutters className={classes.root} sx={sx}>
      <Box
        className={classes.grid}
        sx={{
          display: 'grid',
          maxWidth: '100%',
        }}
      >
        {/* <Box
          className={classes.sidebar}
          sx={(theme) => ({
            display: 'grid',
            alignContent: 'space-between',
            padding: `0 ${theme.spacings.lg} 0 ${theme.page.horizontal}`,
          })}
        >
          <ScrollerDots />
        </Box> */}

        <Box className={classes.scrollerContainer} sx={{ position: 'relative', minWidth: 1, overflow: 'hidden' }}>
          <Box sx={{ textAlign: 'center', overflow: 'hidden' }}>{sidebar}</Box>
          <Scroller
            className={classes.scroller}
            hideScrollbar
            sx={(theme) => ({
              gridColumnGap: theme.spacings.md,
              gridRowGap: theme.spacings.lg,
              paddingRight: theme.page.horizontal,
              gridAutoColumns: responsiveVal(200, 400),
              // transform: 'rotateZ(180deg)',
              // transform: 'translateX(calc(-250px * 1))'
            })}
          >
            {items?.map((item) =>
              item ? (
                <ReviewCard
                  item={item}
                />
              ) : null,
            )}
          </Scroller>
        </Box>
      </Box>
    </Row>
  )
}

TopbarSlider.selectors = selectors
