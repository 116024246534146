import { ContainerProps, useTheme, useMediaQuery, Box } from '@mui/material'
import { m, useTransform } from 'framer-motion'
import React from 'react'
import { extendableComponent } from '@graphcommerce/next-ui/Styles'
import { responsiveVal } from '@graphcommerce/next-ui/Styles/responsiveVal'
import { Row } from '@graphcommerce/next-ui/Row/Row'
import Image from 'next/image'

export type HeroBannerProps = ContainerProps & {
  pageLinks: React.ReactNode
  videoSrc: string
  children: React.ReactNode
  description: string
  width?: any
  height?: any
}

const compName = 'CenteredHeroBanner' as const
const parts = ['root', 'wrapper', 'copy', 'asset', 'animated', 'video'] as const
const { classes } = extendableComponent(compName, parts)

export function CenteredHeroBanner(props: HeroBannerProps) {
  const { pageLinks, videoSrc, children, description, width, height, ...containerProps } = props

  return (
    <>
      <Row maxWidth={'lg'} {...containerProps} className={classes.root}>
        <Box
          sx={{ position: 'relative' }}>
          <Box className={classes.wrapper} sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            justyifyContent: 'center',
            position: 'relative',
            minHeight: responsiveVal(200, 550),
            width: "100%",
            // backgroundImage: `url(${videoSrc})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            borderRadius: '8px'
          })}>
            <Image
              fill
              objectFit='cover'
              objectPosition='center'
              src={videoSrc}
              alt={videoSrc ?? 'Banner'}
            />
            <Box
              className={classes.copy}
              sx={(theme) => ({
                zIndex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                p: 2,
                margin: 'auto',
                '& > *': {
                  zIndex: 1
                },
              })}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignContent: 'center',
                  backgroundColor: 'rgb(255, 255, 255, 0.9)',
                  maxWidth: 560,
                  height: 272,
                  borderRadius: '8px',
                }}>
                {children}

                {pageLinks}
              </Box>
            </Box>
          </Box>
        </Box>
      </Row>
    </>
  )
}
