import { useProductLink } from '@graphcommerce/magento-product'
import { AddProductsToCartForm, ProductListItemsFragment } from '@graphcommerce/magento-product'
import {
  responsiveVal,
  SidebarSlider,
  SidebarSliderProps,
} from '@graphcommerce/next-ui'
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import { RowProductFragment } from '../RowProduct.gql'
import PageLink from 'next/link'

type SwipeableProps = RowProductFragment & ProductListItemsFragment & Pick<SidebarSliderProps, 'sx'>

export function Swipeable(props: SwipeableProps) {
  const { title, items, sx = [] } = props

  if (!items || items.length === 0) return null

  return (
    <AddProductsToCartForm>
      <SidebarSlider
        sx={[
          {
            [SidebarSlider.selectors.scroller]: {
              gridAutoColumns: `minmax(${responsiveVal(180, 800)}, 60vh)`,
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        sidebar={
          <Typography variant='h2' sx={{ textTransform: 'uppercase' }}>
            {title}
          </Typography>
        }
      >
        {items?.map((item) =>
          item ? (
            <PageLink href={useProductLink(item)} passHref style={{ textDecoration: 'none' }}>
              <Card sx={(theme) => ({ maxWidth: '100%', ['&:hover']: { boxShadow: `2px 2px 2px ${theme.palette.grey[500]}` } })}>
                <CardMedia
                  sx={{ height: 200 }}
                  image={item?.small_image?.url as string}
                  title={item?.name as string}
                />
                <CardContent>
                  <Typography gutterBottom variant="h3" component="div">
                    {item?.name}
                  </Typography>
                  <Typography gutterBottom variant="h4" component="div">
                    {item?.price_range.minimum_price.final_price.value}
                  </Typography>
                  <Typography variant="body2" color="text.primary">
                    Lizards are a widespread group of squamate reptiles, with over 6,000
                    species, ranging across all continents except Antarctica
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" variant='text' color='primary'>Shop {item?.name} {'>'}</Button>
                </CardActions>
              </Card>
            </PageLink>
          ) : null,
        )}
      </SidebarSlider>
    </AddProductsToCartForm>
  )
}
