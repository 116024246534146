import { RichText } from '@graphcommerce/graphcms-ui'
import { BlogContent, breakpointVal, responsiveVal } from '@graphcommerce/next-ui'
import { RowBlogContentFragment } from './RowBlogContent.gql'

type RowBlogContentProps = RowBlogContentFragment

export function RowBlogContent(props: RowBlogContentProps) {
  const { content } = props
  if (!content) return null
  return (
    <BlogContent>
      <RichText sxRenderer={{
        'iframe': (theme) => ({
          margin: 0,
          height: responsiveVal(250, 500),
          width: '100%',
        }),
      }} {...content} />
    </BlogContent>
  )
}
