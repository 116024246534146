import { breakpointVal, responsiveVal } from '@graphcommerce/next-ui'
import { Box } from '@mui/material'
import { SeparateHeroBanner } from '../../../ChipLabCustom/Media/SeparateHeroBanner'
import { RichText } from '@graphcommerce/graphcms-ui'

export function VideoSpecs(props) {
  const { asset, name, productCopy, fontSize = 'subtitle1' } = props

  return (
    <>
      <SeparateHeroBanner
        videoSrc={asset.url}
        description={''}
      >
        <Box
        >
          <RichText
            {...productCopy}
            sxRenderer={{
              'heading-two': { typography: 'h1' },
              paragraph: { typography: 'subtitle1' },
            }}
            withMargin
          />
        </Box>
      </SeparateHeroBanner>
    </>
  )
}
