import { Box, Typography, Card, CardContent, Fab } from "@mui/material";
import { ProductReviewChipSummary } from "../Configurable/ProductReviewChipSummary";
import { iconPerson, IconSvg } from "@graphcommerce/next-ui";

function ReviewCard(props) {
  const { item } = props;


  const icon = iconPerson;
  return (
    <>
      <Card sx={(theme) => ({ maxWidth: '100%' })}>
        <CardContent>
          <ProductReviewChipSummary rating_summary={item?.average_rating} />
          <Box
            sx={{ textAlign: 'center', p: 3 }}>
            <Typography variant="h3" color="text.primary" sx={{ overflowWrap: 'break-word' }}>
              {item?.summary}
            </Typography>
          </Box>
          <Box
            sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="body1" color="text.primary" sx={{ overflowWrap: 'break-word' }}>
              {item?.text}
            </Typography>
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Fab size="medium">
              <IconSvg src={icon} size={'large'}></IconSvg>
            </Fab>
            <Typography gutterBottom variant="h2" component="div" sx={{ textAlign: 'center', paddingTop: 2, paddingLeft: 2 }}>
              {item?.nickname}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default ReviewCard;