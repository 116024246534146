import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const numberFormat = (value) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(value);

export default function PriceTable(props) {
  const { tier_data } = props;
  const rows = tier_data?.data?.products?.items[0]?.price_tiers;
  const highestPrice = rows[0].final_price?.value;
  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Quantity</StyledTableCell>
              <StyledTableCell align="left">Price / Chip</StyledTableCell>
              <StyledTableCell align="left">Total Price</StyledTableCell>
              <StyledTableCell align="left">Original Price</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.quantity}>
                <StyledTableCell component="th" scope="row">
                  {row.quantity.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                </StyledTableCell>
                <StyledTableCell align="left">{numberFormat(row.final_price.value)} / chip</StyledTableCell>
                <StyledTableCell align="left">{numberFormat(row.final_price.value * row.quantity)}</StyledTableCell>
                <StyledTableCell align="left" style={{ textDecoration: 'line-through', color: 'GrayText' }}>{numberFormat(highestPrice * row.quantity)}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div >
  );
}
