import { ScrollerProvider } from '@graphcommerce/framer-scroller'
import { Typography } from '@mui/material'
import { TopbarSlider } from '../../../ChipLabCustom/Media/TopbarSlider'

export function Testimonials(props) {
  const { title, items, sx = [] } = props

  let aggregatedReviews = [] as any;
  let fullArray = [] as any;

  if (items) {
    for (let item of items) {
      if (item?.reviews?.items?.length > 0) {
        aggregatedReviews.push(...item?.reviews?.items);
      }
    }
  }

  for (var i = 0; i < 20; i++) {
    fullArray.push(...aggregatedReviews);
  }

  if (!items || items.length === 0) return null

  return (
    <>
      <ScrollerProvider scrollSnapAlign='center'>
        <TopbarSlider
          sidebar={
            < Typography variant='h2' sx={{ textTransform: 'uppercase', marginBottom: 4 }}>
              {title}
            </Typography >
          }
          items={fullArray}
        >
        </TopbarSlider >
      </ScrollerProvider>
    </>
  )
}
