import { RichText } from '@graphcommerce/graphcms-ui'
import { breakpointVal, responsiveVal } from '@graphcommerce/next-ui'
import { Box, Button, Grid, useMediaQuery, useTheme } from '@mui/material'
import { HeroBanner } from '../../../ChipLabCustom/Media/HeroBanner'
import { SeparateHeroBanner } from '../../../ChipLabCustom/Media/SeparateHeroBanner'
import { RowHeroBannerFragment } from '.././RowHeroBanner.gql'

export function Video(props: RowHeroBannerFragment) {
  const { copy, lg, pageLinks, description } = props

  return (
    <>
      <SeparateHeroBanner
        videoSrc={lg.url}
        description={description as string}
      >
        <RichText
          {...copy}
          sxRenderer={{
            'heading-one': (theme) => ({
              textTransform: 'uppercase' as const,
              maxWidth: '70%',
              textAlign: 'center' as const,
              margin: 0,
              marginBottom: theme.spacings.md,
              ...breakpointVal('fontSize', 20, 42, theme.breakpoints.values),
              [theme.breakpoints.up('md')]: {
                textAlign: 'left',
                maxWidth: '100%',
              },
            }),
          }}
        />
      </SeparateHeroBanner>
    </>
  )
}
