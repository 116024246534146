import { breakpointVal, extendableComponent, IconSvg, responsiveVal, Row, SidebarSlider } from "@graphcommerce/next-ui";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { iconStar } from '@graphcommerce/next-ui/icons'
import { Link } from "@mui/material";
import { RichText } from "@graphcommerce/graphcms-ui";
import Image from "next/image";
import useProductIDs from "components/ChipLabCustom/ID/useProductIDs";

const { classes, selectors } = extendableComponent('SidebarSlider', [
  'root',
  'grid',
  'sidebar',
  'scrollerContainer',
  'scroller',
  'sliderButtons',
  'centerLeft',
  'centerRight',
] as const)

export const Carouse = (props) => {
  const { carouselItem, sx1 = [], asset, productCopy: copy, title, aggregatedReviews } = props

  const theme = useTheme();

  const noImage = useMediaQuery(theme.breakpoints.only('md'));
  const noImage2 = useMediaQuery(theme.breakpoints.only('xs'));
  const noSecondary = useMediaQuery(theme.breakpoints.down('md'));
  const reviews = JSON.parse(aggregatedReviews?.GetReviews?.reviews ?? "{}");
  const total_rating = aggregatedReviews?.GetReviews?.total_rating;
  const { total_count } = reviews;
  const { allIDs } = useProductIDs();

  const disabledStar = (
    <IconSvg
      src={iconStar}
      sx={(theme) => ({ stroke: 'none', fill: theme.palette.grey[300] })}
    />
  )

  const star = (
    <IconSvg
      src={iconStar}
      sx={{ stroke: 'none', fill: '#FFDA1C' }}
    />
  )

  function calcWorkingDays(fromDate, days) {
    if (Number.isNaN(Number(days))) {
      let count = 0;
      while (count < 8) {
        fromDate.setDate(fromDate.getDate() + 1);
        if (fromDate.getDay() != 0 && fromDate.getDay() != 6)
          count++;
      }
      return fromDate;
    }
    let count = 0;
    while (count < days) {
      fromDate.setDate(fromDate.getDate() + 1);
      if (fromDate.getDay() != 0 && fromDate.getDay() != 6)
        count++;
    }
    return fromDate;
  }

  if (!carouselItem || carouselItem.length === 0) return null

  return (
    <Row maxWidth={'lg'} className={classes.root}
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacings.xl,
        },
        marginTop: theme.spacings.md,
        marginBottom: 15
      })}>
      <Box
        sx={{
          maxWidth: 'lg',
          margin: 'auto',
          display: 'grid',
          border: '1px solid #E0E0E0',
          minHeight: "256px",
          height: '100%',
          zIndex: 0
        }}>
        <Box
          component={'h2'}
          sx={(theme) => ({
            display: 'flex',
            width: responsiveVal(325, 476),
            height: 25,
            justifySelf: 'center',
            justifyContent: 'center',
            zIndex: 2,
            marginTop: -3,
            [theme.breakpoints.down('sm')]: {
              marginTop: -2.5
            },
            [theme.breakpoints.down(281)]: {
              display: 'none'
            },
            backgroundColor: theme.palette.background.default,
          })}>
          {title}
        </Box>
        <Box
          className={classes.grid}
          sx={(theme) => ({
            display: 'flex',
            padding: 1,
            [theme.breakpoints.up(281)]: {
              padding: 5,
            },
            mt: -3,
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column'
            },
            justifyContent: 'center'
          })}
        >
          <Box
            sx={{
              display: 'flex',
              marginY: 'auto',
              justifyContent: 'center',
            }}
          >
            {(!noImage && !noImage2) && <Box
              sx={{
                backgroundImage: `url(${asset.url})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                width: 183,
                minHeight: 144,
                position: 'relative'
              }}>
              <Image
                fill
                src={asset.url}
                alt={asset?.alt ?? 'Image Box'}
                objectFit='cover'
                objectPosition='center'
              />

            </Box>}
            {!noSecondary ? carouselItem.map((item) => (
              <Box
                key={item.name}
                sx={{
                  minHeight: 144,
                  marginX: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                  alignItems: 'center'
                }}>
                <Box
                  component='h3'
                  sx={{
                    display: 'flex',
                    color: '#C33DC6',
                    my: 0,
                    minWidth: 210,
                  }}>
                  <img src={item.asset.url} style={{
                    marginRight: 8,
                    marginTop: 4,
                    height: 28,
                    width: 29
                  }}
                    alt={item.asset.alt ?? item.asset.url}
                  />
                  {item.name}
                </Box>
                <Box component='h3'
                  sx={{
                    my: 0
                  }}>
                  {item.description}
                </Box>
                <Box>
                  {`Get it around ${calcWorkingDays(new Date(), item.description2).toLocaleDateString('en-us', { weekday: "long", month: "short", day: "numeric" })}`}
                </Box>
                <Link
                  href={'/shipping'}
                  style={{ color: theme.palette.primary.main }}
                >
                  Get Delivery Estimate
                </Link>
              </Box>
            )) :
              <Box
                sx={{
                  minHeight: 144,
                  marginX: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center'
                }}>
                <Box
                  component='h3'
                  sx={{
                    display: 'flex',
                    color: '#C33DC6',
                    my: 0,
                    minWidth: 210,
                    justifyContent: 'center'
                  }}>
                  <img src={carouselItem?.[0].asset.url} style={{
                    marginRight: 8,
                    marginTop: 1,
                    height: 28,
                    width: 29
                  }}
                    alt={carouselItem?.[0].asset.alt ?? carouselItem?.[0].asset.url}
                  />
                  {carouselItem?.[0].name}
                </Box>
                <Box component='h3'
                  sx={{
                    my: 0
                  }}>
                  {carouselItem?.[0].description}
                </Box>
                <Box>
                  {`Get it around ${calcWorkingDays(new Date(), carouselItem?.[0].description2).toLocaleDateString('en-us', { weekday: "long", month: "short", day: "numeric" })}`}
                </Box>
                <Link
                  href={'/shipping'}
                  style={{ color: theme.palette.primary.main }}
                >
                  Get Delivery Estimate
                </Link>
              </Box>
            }
          </Box>
          <Box
            sx={{
              border: 1,
              height: 160,
              marginY: 'auto',
              borderColor: '#E0E0E0',
              [theme.breakpoints.down('md')]: {
                height: 0,
                marginY: 2
              },
            }}
          ></Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              marginY: 'auto',
              maxWidth: 531,
              ml: 4,
            })}
          >
            <Box sx={(theme) => ({
              display: 'flex',
              [theme.breakpoints.only('xs')]: {
                flexDirection: 'column',
                alignItems: 'center',
              }
            })}>
              <Box
                sx={[
                  {
                    width: 'max-content',
                    marginRight: 3,
                    position: 'relative',
                    top: 3,
                  },
                ]}
              >
                <div>
                  {disabledStar}
                  {disabledStar}
                  {disabledStar}
                  {disabledStar}
                  {disabledStar}
                </div>
                <Box
                  style={{ width: `${total_rating}%` }}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    overflow: 'hidden',
                    '& > div': { whiteSpace: 'nowrap' },
                  }}
                >
                  <div>
                    {star}
                    {star}
                    {star}
                    {star}
                    {star}
                  </div>
                </Box>
              </Box>
              <Typography sx={(theme) => ({ color: theme.palette.text.primary })}>
                <strong>{((total_rating ?? 0) / 20).toFixed(1).replace(/[.,]0$/, "")}</strong> out of 5 based on <strong>{(total_count ?? 0).toLocaleString()} reviews</strong>
              </Typography>
            </Box>
            {!noImage2 && <Box>
              <RichText raw={copy?.raw}
                sxRenderer={{
                  'all': {
                    ...breakpointVal('fontSize', 16, 16, theme.breakpoints.values)
                  }
                }} />
            </Box>}
            {!noSecondary && <Box
            >
              <Link
                href={`/reviews?ids=${allIDs}`}
                style={{ color: theme.palette.primary.main }}
              >
                See All Reviews
              </Link>
            </Box>}
          </Box>
        </Box>
        {noSecondary && <Box
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              mb: 2
            }
          })}
        >
          <Link
            href={`/reviews?ids=${allIDs}`}
            style={{ color: theme.palette.primary.main }}
          >
            See All Reviews
          </Link>
        </Box>}
      </Box>
    </Row>
  );
};
