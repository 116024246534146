import { responsiveVal, extendableComponent, useColorMode } from '@graphcommerce/next-ui'
import { Box, SxProps, Theme, useTheme } from '@mui/material'
import { ColorSwatchDataFragment } from '@graphcommerce/magento-product-configurable/Swatches/ColorSwatchData.gql'
import { SwatchDataProps } from '@graphcommerce/magento-product-configurable/Swatches/types'
import { useLazyQuery } from '@graphcommerce/graphql'
import { TemplatesDocument } from './gql/GetTemplates.gql'
import { useContext, useEffect } from 'react'
import TemplateContext from '../TemplateContext/template-context'

type ColorSwatchDataProps = ColorSwatchDataFragment &
  SwatchDataProps & {
    sx?: SxProps<Theme>
    template?: any
    value_index?: number
    setTemplate?: any
    setError?: any
    selected?: any
    setSelected?: any
  }

type OwnerState = Pick<SwatchDataProps, 'size'>
const name = 'ColorSwatchData' as const
const parts = ['root', 'color', 'label'] as const
const { withState } = extendableComponent<OwnerState, typeof name, typeof parts>(name, parts)

export function ColorSwatchData(props: ColorSwatchDataProps) {
  const { value, value_index, store_label, size, sx, template, setTemplate, setError, selected, setSelected } = props
  const classes = withState({ size })
  const { categoryId } = useContext(TemplateContext);
  const [getTemplate, { loading, data }] = useLazyQuery(TemplatesDocument);
  // const { currentMode } = useColorMode();
  const theme = useTheme()

  useEffect(() => {
    if (data?.products?.items) {
      if (!loading && data?.products?.items?.length > 0) {
        setTemplate(data?.products?.items?.[0]);
        setError("");
      } else if (!loading) {
        setError(`${store_label} unavailable`);
      }
    }
  }, [data, loading]);

  function retrieveNewTemplate(e) {
    e.preventDefault();
    getTemplate({ fetchPolicy: 'cache-and-network', variables: { filters: { category_id: { in: [categoryId.toString()] }, edge_pattern_val: { in: [template?.edge_pattern_val] }, color_val: { in: [value_index?.toString() as any] }, template_id: { eq: template?.template_id.toString() } } } });
    if (setSelected) {
      setSelected(value);
    }
  }

  return (
    <Box className={selected === value ? 'selected' : classes.root} sx={[{
      borderRadius: '50%',
      padding: 0.5,
      '&.selected': {
        border: `1.5px solid ${theme.palette['default']?.main ?? theme.palette.primary.main}`,
        boxShadow: `inset 0 0 0 1px ${theme.palette['default']?.main ?? theme.palette.primary.main}`,
      },
    }, ...(Array.isArray(sx) ? sx : [sx])]}>
      <Box
        className={classes.color}
        style={{ backgroundColor: value ?? undefined }}
        onClick={retrieveNewTemplate}
        sx={[
          {
            margin: '0 auto',
            height: 30,
            width: 30,
            borderRadius: '50%',
            border: 2,
            // borderColor: currentMode === 'dark' ? 'gray' : 'black',
            borderColor: 'black',
            '&.sizeSmall': {
              height: responsiveVal(8, 20),
              width: responsiveVal(8, 20),
              marginTop: responsiveVal(2, 4),
            },
          }
        ]}
      />
    </Box>
  )
}
