import { ContainerWithHeader, responsiveVal } from '@graphcommerce/next-ui'
import { Box, styled, useMediaQuery, useTheme } from '@mui/material'
import { Link as MuiLink } from '@mui/material'
import { m } from 'framer-motion'
import GridItem from '../../../ChipLabCustom/Configurable/GridItem'
import GridItemBase from '../../../ChipLabCustom/Configurable/GridItemBase'

export function Grid(props) {
  const { title, pageLinks, asset, items } = props

  const MotionBox = styled(m.div)({});
  const theme = useTheme();
  const inSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ContainerWithHeader
      title={title}
      rightArea={pageLinks.map((pageLink) => (
        <MuiLink color='inherit' href={pageLink.url} key={pageLink.url} underline='always'>
          {pageLink.title}
        </MuiLink>
      ))}
    >
      <Box
        sx={[
          (theme) => ({
            display: 'grid',
            gridColumnGap: theme.spacings.sm,
            gridRowGap: theme.spacings.md,
            position: 'relative',
            gridTemplateColumns: { xs: `repeat(1, minmax(0, 1fr))`, sm: `repeat(1, minmax(0, 1fr))`, md: `repeat(3, minmax(0, 1fr))`, lg: `repeat(3, minmax(0, 1fr))` },
          }),
        ]}
      >
        {asset && <MotionBox
          layout
          sx={[
            {
              willChange: 'transform',
              position: 'relative',
              minHeight: '50%',
              overflow: 'hidden',
              borderRadius: '8px',
              maxHeight: responsiveVal(275, 900),
            },
          ]}
        >
          <video
            src={asset?.url}
            autoPlay
            muted
            loop
            style={{ height: "100%", width: "100%", objectFit: 'cover' }}
            playsInline
            disableRemotePlayback
          />
        </MotionBox>}
        {inSmall ? <Box
          sx={[
            (theme) => ({
              display: 'grid',
              gridColumnGap: theme.spacings.sm,
              gridRowGap: theme.spacings.md,
              height: '100%',
              gridTemplateColumns: { xs: `repeat(1, 1fr)`, sm: `repeat(2, 1fr)` },
            }),
          ]}
        >
          {items?.map((item) => {
            return item ? (
              <GridItemBase key={item.url_key} item={item}>
                <GridItem
                  item={item}
                />
              </GridItemBase>

            )
              : null
          }
          )}
        </Box> :
          <>
            {items?.map((item) => {
              return item ? (
                <GridItemBase key={item.url_key} item={item}>
                  <GridItem
                    item={item}
                  />
                </GridItemBase>
              )
                : null
            }
            )}
          </>
        }
      </Box>
    </ContainerWithHeader >
  )
}
