import { Box } from '@mui/material'

export function TextSwatchData(props) {
  const { value, sx = [] } = props

  return (
    <Box
      sx={[{
      }, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      {value}
    </Box>
  )
}
