import { Box, SxProps, Theme, Typography } from '@mui/material'
import React from 'react'
import { Row } from '@graphcommerce/next-ui/Row/Row'
import { extendableComponent } from '@graphcommerce/next-ui/Styles'

const { selectors, classes } = extendableComponent('ContainerWithHeader', [
  'root',
  'head',
  'title',
  'right',
] as const)

export type ContainerWithHeaderProps = {
  title: any
  rightArea: React.ReactNode
  children: React.ReactNode
  sx?: SxProps<Theme>
  centered?: boolean
}

export function ContainerWithHeader(props: ContainerWithHeaderProps) {
  const { title, rightArea, children, centered = false, sx } = props

  return (
    <Row className={classes.root} sx={sx}>
      <Box
        className={classes.head}
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          alignItems: 'end',
          marginBottom: theme.spacings.md,
          alignContent: 'center',
          [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
          }
        })}
      >
        <Typography
          variant='h5'
          component='h2'
          className={classes.title}
          sx={{
            lineHeight: 1,
            textTransform: 'uppercase',
            justifyContent: centered ? 'flex-end' : undefined,
            display: centered ? 'flex' : undefined
          }}
        >
          {title}
        </Typography>
        <Typography component='div' variant='subtitle1' className={classes.right}>
          {rightArea}
        </Typography>
      </Box>
      {children}
    </Row>
  )
}

ContainerWithHeader.selectors = selectors
