import { RichText } from '@graphcommerce/graphcms-ui'
import { breakpointVal, responsiveVal } from '@graphcommerce/next-ui'
import { Box, Button, Grid, useMediaQuery, useTheme } from '@mui/material'
import PageLink from 'next/link'
import { HeroBanner } from '../../../ChipLabCustom/Media/HeroBanner'
import { RowHeroBannerFragment } from '.././RowHeroBanner.gql'
import { Fragment } from 'react'

export function Banner(props: RowHeroBannerFragment) {
  const { copy, pageLinks, description, title1, xl, lg, md, sm, xs } = props
  const t = useTheme();
  const xsmall = useMediaQuery(t.breakpoints.only('xs'));
  const small = useMediaQuery(t.breakpoints.only('sm'));
  const medium = useMediaQuery(t.breakpoints.only('md'));
  const large = useMediaQuery(t.breakpoints.only('lg'));
  const xlarge = useMediaQuery(t.breakpoints.only('xl'));
  let asset: any = xs;

  //gross but MUI does not have a way to detect active breakpoints

  if (xsmall) {
    asset = xs;
  } else if (small) {
    asset = sm;
  } else if (medium) {
    asset = md;
  } else if (large) {
    asset = lg;
  } else if (xlarge) {
    asset = xl;
  }

  return (
    <>
      <HeroBanner
        pageLinks={pageLinks.map((pageLink) => (
          <Fragment key={pageLink?.url}>
            <Box
              key={pageLink?.url}
              sx={{
                marginTop: responsiveVal(12, 24),
                marginRight: responsiveVal(12, 24),
              }}>
              <PageLink key={pageLink?.url} href={pageLink?.url} passHref style={{ textDecoration: 'none' }}>
                <Button key={pageLink?.url} variant={pageLink?.buttonVariant as any} size='medium' color={pageLink.buttonPriority as any}
                  sx={(theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      width: '100%',
                    },
                    borderRadius: '200px',
                  })} >
                  {pageLink?.title}
                </Button>
              </PageLink>
            </Box>
          </Fragment>
        ))
        }
        videoSrc={asset}
        description={description as string}
        title={title1?.toUpperCase() as string}
      >
        <RichText
          {...copy}
          sxRenderer={{
            'heading-one': (theme) => ({
              margin: 0,
              marginBottom: theme.spacings.xs,
              textAlign: 'left',
              maxWidth: '100%',
              ...breakpointVal('fontSize', 40, 42, theme.breakpoints.values),
              '& strong': (theme) => ({
                color: theme.palette.primary.main
              }),
            }),
          }}
        />
      </HeroBanner >
    </>
  )
}
