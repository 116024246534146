import { useMutation } from '@graphcommerce/graphql'
import {
  CustomerDocument,
} from '@graphcommerce/magento-customer'
import {
  Button,
  LayoutTitle,
  extendableComponent,
  Row,
  responsiveVal,
  IconSvg,
} from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Box, SxProps, TextField, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { NewsletterSignUpDocument } from '../../../graphql/NewsletterSignUp.gql'
import iconCheckmark from './checkmark.svg'
import { useRouter } from 'next/router'

export type AccountSignInUpFormProps = { sx?: SxProps<Theme> }

const parts = ['root', 'titleContainer'] as const
const { classes } = extendableComponent('AccountSignInUpForm', parts)

export function RowNewsletter(props) {
  const { header, buttonText, description } = props
  const router = useRouter();

  const t = useTheme();
  const needlessXs = useMediaQuery(t.breakpoints.only('xs'));

  const [msg, setMsg] = useState("");
  const [func, res] = useMutation(NewsletterSignUpDocument, { onCompleted: response, onError: errorHandler });

  function response() {
    setMsg("Thank you for subscribing to our newsletter!")
  }

  function errorHandler(error) {
    if (error) {
      setMsg(error.message);
    }
  }

  function submit(e) {
    e.preventDefault();
    router.push('/newsletter')
    func({ variables: { Email: e.target[0].value } })
  }

  return (
    <Row maxWidth='lg'>
      <Box sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        height: 144,
        position: 'relative',
        overflow: 'hidden',
        maxWidth: 'lg',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: { height: 'sm' },
        [theme.breakpoints.only('md')]: {
          minHeight: '100%',
          alignItems: 'center',
        },
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          height: '100%'
        },
        padding: 2,
        backgroundColor: '#FFDA1C',
        borderRadius: responsiveVal(theme.shape.borderRadius * 2, theme.shape.borderRadius * 3)
      })}>

        <Box className={classes.titleContainer} key='email' >
          <LayoutTitle variant='h2' gutterBottom={false} gutterTop={false} sx={{ color: 'black' }}>
            <Trans id={header} />
          </LayoutTitle>
          <Typography variant='h6' component='p' sx={(theme) => ({
            color: 'black',
            [theme.breakpoints.down('md')]: {
              textAlign: 'center'
            }
          })}>
            <Trans id={description} />
          </Typography>
        </Box>

        <form noValidate onSubmit={submit} key='newsletterForm'  >

          <Box sx={(theme) => ({
            display: 'flex', flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
              justifyContent: 'center'
            }
          })}>
            <Box sx={(theme) => ({
              maxWidth: 500,
              height: 64,
              border: 1,
              borderColor: 'gray',
              borderRadius: '50px',
              display: 'flex',
              justifyContent: 'flex-end',
              backgroundColor: "#FFFFFF",
              [theme.breakpoints.down('md')]: {
                mt: 3,
              },
            })}>
              <TextField
                key='email'
                color='secondary'
                type='text'
                autoComplete='email'
                placeholder={'Your Email Address'}
                fullWidth
                inputProps={{
                  style: {
                    color: '#979797'
                  }
                }}
                sx={{
                  "& fieldset": { border: 'none' },
                }}
              />
              <Button
                type='submit'
                variant='pill'
                color='primary'
                size='large'
                sx={{
                  width: needlessXs ? undefined : 200,
                  height: 56,
                  padding: 1,
                  mt: '3px',
                  mr: '3px',
                }}
              >
                {!needlessXs ? <Trans id={buttonText} /> : <IconSvg sx={{ width: 30, height: 30 }} src={iconCheckmark} />}
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
            }}>
            {msg}
          </Box>
        </form>
      </Box>
    </Row>
  )
}
