import { ProductListItemsFragment } from '@graphcommerce/magento-product'
import {
  SidebarSliderProps,
} from '@graphcommerce/next-ui'
import { Typography } from '@mui/material'
import { SidebarSlider } from '../../../ChipLabCustom/Media/SidebarSlider'
import RowCategory from '../../../ChipLabCustom/RowCategory/RowCategory'
import { RowProductFragment } from '../RowProduct.gql'

type SwipeableProps = RowProductFragment & ProductListItemsFragment & Pick<SidebarSliderProps, 'sx'>

export function Category(props) {
  const { title, items, sx = [] } = props

  if (!items || items.length === 0) return null

  return (
    <SidebarSlider
      sidebar={
        < Typography variant='h2' sx={{ textTransform: 'uppercase' }}>
          {title}
        </Typography >
      }
    >
      {items?.map((item) =>
        item ? (
          <RowCategory
            item={item}
          />
        ) : null,
      )}
    </SidebarSlider >
  )
}
