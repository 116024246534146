import { AddProductsToCartFab, ProductListItemRenderer } from '@graphcommerce/magento-product'
import { ProductReviewSummary } from '@graphcommerce/magento-review'
import { ProductWishlistChip } from '@graphcommerce/magento-wishlist'
import { ProductListItemBundle } from '../ChipLabCustom/Product/ProductListItemBundle'
import { ProductListItemCategoryConfigurable } from '../ChipLabCustom/Product/ProductListItemCategoryConfigurable'
import { ProductListItemDownloadable } from '../ChipLabCustom/Product/ProductListItemDownloadable'
import { ProductListItemGrouped } from '../ChipLabCustom/Product/ProductListItemGrouped'
import { ProductListItemSimple } from '../ChipLabCustom/Product/ProductListItemSimple'
import { ProductListItemVirtual } from '../ChipLabCustom/Product/ProductListItemVirtual'

export const productListRenderer: ProductListItemRenderer = {
  SimpleProduct: (props) => {
    const { sku } = props
    return (
      <ProductListItemSimple
        {...props}
        aspectRatio={[1, 1]}
        bottomLeft={<ProductReviewSummary {...props} />}
        topRight={<ProductWishlistChip {...props} />}
        bottomRight={<AddProductsToCartFab sku={sku} />}
      />
    )
  },
  ConfigurableProduct: (props) => (
    <ProductListItemCategoryConfigurable
      {...props}
      aspectRatio={[1, 1]}
      swatchLocations={{
        topLeft: [],
        topRight: [], // ['size']
        bottomLeft: [],
        bottomRight: ['dominant_color'],
      }}
      bottomLeft={<ProductReviewSummary {...props} />}
      topRight={<ProductWishlistChip {...props} />}
    />
  ),
  BundleProduct: (props) => (
    <ProductListItemBundle
      {...props}
      aspectRatio={[1, 1]}
      bottomLeft={<ProductReviewSummary {...props} />}
      topRight={<ProductWishlistChip {...props} />}
    />
  ),
  VirtualProduct: (props) => {
    const { sku } = props
    return (
      <ProductListItemVirtual
        {...props}
        aspectRatio={[1, 1]}
        bottomLeft={<ProductReviewSummary {...props} />}
        topRight={<ProductWishlistChip {...props} />}
        bottomRight={<AddProductsToCartFab sku={sku} />}
      />
    )
  },
  DownloadableProduct: (props) => (
    <ProductListItemDownloadable
      {...props}
      aspectRatio={[1, 1]}
      bottomLeft={<ProductReviewSummary {...props} />}
      topRight={<ProductWishlistChip {...props} />}
    />
  ),
  GroupedProduct: (props) => (
    <ProductListItemGrouped
      {...props}
      aspectRatio={[1, 1]}
      bottomLeft={<ProductReviewSummary {...props} />}
      topRight={<ProductWishlistChip {...props} />}
    />
  ),
  // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // // @ts-ignore GiftCardProduct is only available in Commerce
  // GiftCardProduct: (props) => (
  //   <ProductListItem {...props} aspectRatio={[1, 1]} />
  // ),
}
