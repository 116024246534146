import { ContainerProps, useTheme, useMediaQuery, Box } from '@mui/material'
import React from 'react'
import { extendableComponent } from '@graphcommerce/next-ui/Styles'

export type HeroBannerProps = ContainerProps & {
  children: React.ReactNode
  title1: any
}

const compName = 'HeroBanner' as const
const parts = ['root', 'wrapper', 'copy', 'asset', 'animated', 'video'] as const
const { classes } = extendableComponent(compName, parts)

export function NoImageBanner(props: HeroBannerProps) {
  const { children, title1 } = props
  const t = useTheme()
  const matches = useMediaQuery(t.breakpoints.up('md'))

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.wrapper} sx={{
          position: 'relative',
          height: 150,
          background: 'linear-gradient(to left, #141E30 80%, #000000)',
          mb: 10
        }}>
          <Box
            className={classes.copy}
            sx={(theme) => ({
              zIndex: 1,
              color: theme.palette.secondary.contrastText,
              position: 'relative',
              display: 'grid',
              justifyItems: matches ? 'left' : 'center',
              alignContent: 'top',
              paddingTop: `calc(${theme.spacings.lg} - ${theme.spacings.md})`,
              minHeight: '100%',
              paddingLeft: theme.spacings.sm,
              '& > *': {
                zIndex: 1
              },
              [theme.breakpoints.up('lg')]: {
                paddingTop: `calc(${theme.spacings.lg} - ${theme.spacings.md})`,
                justifySelf: 'center',
                margin: 'auto',
                maxWidth: 'lg',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                maxWidth: 'md',
                justifySelf: 'left',
                marginY: 'auto',
                [theme.breakpoints.up('md')]: {
                  display: 'flex',
                }
              })}>
              {title1}
              {children}
            </Box>
          </Box>
        </Box>
      </Box >
    </>
  )
}
