import { useQuery } from "@graphcommerce/graphql";
import { ConfigurableContextProvider } from "@graphcommerce/magento-product-configurable";
import React, { useEffect, useState } from "react";
import { TemplateProductPageDocument } from "../../../graphql/TemplateProductPage.gql";

export default function GridItemBase(props) {
  const { item, children } = props;
  const { data, loading } = useQuery(TemplateProductPageDocument, { variables: { urlKey: item?.url_key } });
  const [typeProduct, setTypeProduct] = useState() as any;

  useEffect(() => {
    if (!loading) {
      if (data) {
        const typeProducts = data?.typeProducts;
        setTypeProduct(typeProducts?.items?.[0]);
      }
    }
  }, [loading, data])

  if (!typeProduct) return <>{children}</>
  return (
    <ConfigurableContextProvider {...typeProduct} sku={item?.sku} >
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) return null

        return React.cloneElement(child, {
          // @ts-ignore
          ...child.props,
          typeProduct: typeProduct
        })
      })}
    </ConfigurableContextProvider>
  );
}