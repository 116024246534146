import { ContainerProps, useTheme, useMediaQuery, Box } from '@mui/material'
import React from 'react'
import { extendableComponent } from '@graphcommerce/next-ui/Styles'
import Image from 'next/image'

export type HeroBannerProps = ContainerProps & {
  pageLinks: React.ReactNode
  videoSrc: any
  children: React.ReactNode
  description: string
  title: string
}

const compName = 'HeroBanner' as const
const parts = ['root', 'wrapper', 'copy', 'asset', 'animated', 'video'] as const
const { classes } = extendableComponent(compName, parts)

export function HeroBanner(props: HeroBannerProps) {
  const { pageLinks, videoSrc, children, description, title } = props
  const t = useTheme()
  const matches = useMediaQuery(t.breakpoints.up('md'))

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.wrapper} sx={{
          position: 'relative',
          height: videoSrc?.height ?? 640,
        }}>
          <Image
            fill
            src={videoSrc?.url}
            alt={videoSrc?.url ?? 'Banner'}
            objectPosition='center'
            objectFit='cover'
            loading='eager'
            style={{
              backgroundRepeat: 'no-repeat'
            }}
          />
          <Box
            className={classes.copy}
            sx={(theme) => ({
              zIndex: 1,
              color: theme.palette.secondary.contrastText,
              position: 'relative',
              display: 'grid',
              justifyItems: matches ? 'left' : 'center',
              alignContent: 'top',
              paddingTop: `calc(${theme.spacings.lg} - ${theme.spacings.md})`,
              minHeight: '100%',
              paddingLeft: theme.spacings.sm,
              '& > *': {
                zIndex: 1
              },
              [theme.breakpoints.up('lg')]: {
                paddingTop: `calc(${theme.spacings.lg} - ${theme.spacings.md})`,
                justifySelf: 'center',
                margin: 'auto',
                maxWidth: 'lg',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                maxWidth: 'sm',
                marginY: matches ? 'auto' : 0,
                justifySelf: 'left',
                [theme.breakpoints.only('xs')]: {
                  padding: 2,
                  paddingTop: 5,
                }
              })}>
              {title}
              {children}
              <Box
                sx={(theme) => ({
                  [theme.breakpoints.only('xs')]: {
                    paddingRight: 4,
                    paddingBottom: 1
                  }
                })}>
                {description}
              </Box>
              <Box
                sx={(theme) => ({
                  [theme.breakpoints.up('sm')]: {
                    display: 'flex',
                    flexDirection: 'flex-start',
                  },
                })}>
                {pageLinks}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
