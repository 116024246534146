import { RichText } from '@graphcommerce/graphcms-ui'
import { Box, Button, Typography } from '@mui/material'
import PageLink from 'next/link'
import { CenteredHeroBanner } from '../../../ChipLabCustom/Media/CenteredHeroBanner'
import { RowHeroBannerFragment } from '.././RowHeroBanner.gql'
import { Fragment } from 'react'

export function CenteredBanner(props: RowHeroBannerFragment) {
  const { copy, lg, pageLinks, description, sm, md, title1 } = props

  return (
    <>
      <CenteredHeroBanner
        height={lg?.height}
        width={lg?.width}
        pageLinks={pageLinks.map((pageLink) => (
          <Fragment key={pageLink.url}>
            <Box
              key={pageLink.url}
            >
              <PageLink key={pageLink.url} href={pageLink.url} passHref style={{ textDecoration: 'none' }}>
                <Button key={pageLink.url} variant={pageLink.buttonVariant as any} size='large' color={pageLink.buttonPriority as any}>
                  {pageLink.title}
                </Button>
              </PageLink>
            </Box>
          </Fragment>
        ))
        }
        videoSrc={lg.url as string}
        description={description as string}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            textAlign: 'center',
            marginX: '40px'
          }}>
          <Typography variant='h2' sx={{ padding: 1, color: 'black' }}>{title1}</Typography>
          <RichText {...copy} sxRenderer={{
            'paragraph': {
              color: 'black'
            }
          }} />
        </Box>
      </CenteredHeroBanner >
    </>
  )
}
