import { responsiveVal } from '@graphcommerce/next-ui'
import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import PageLink from 'next/link'
import Link from 'next/link'
import { Link as MuiLink } from '@mui/material'
import { Image } from '@graphcommerce/image'
import { useContext } from 'react'
import ProductContext from '../../../ChipLabCustom/ProductContext/product-context'
import { ContainerWithHeader } from '../../../ChipLabCustom/Media/ContainerWithHeader'

export function CMS(props) {
  const { title, pageLinks, carouselItem } = props

  let size = 'small';
  const sx = [];
  const { changeFilters } = useContext(ProductContext);

  return (
    <ContainerWithHeader
      title={title}
      rightArea={pageLinks.map((pageLink) => (
        <PageLink href={pageLink.url} key={pageLink.url} passHref style={{ textDecoration: 'none' }}>
          <MuiLink color='inherit' href={pageLink.url} underline='always'>
            {pageLink.title}
          </MuiLink>
        </PageLink>
      ))}
    >
      <Box
        sx={[
          (theme) => ({
            display: 'grid',
            gridColumnGap: theme.spacings.md,
            gridRowGap: theme.spacings.md,
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              flexDirection: 'column',
            },
          }),
          size === 'small' && {
            gridTemplateColumns: `repeat(auto-fill, minmax(${responsiveVal(150, 280)}, 1fr))`,
          },
          size === 'normal' && {
            gridTemplateColumns: { xs: `repeat(2, 1fr)`, md: `repeat(3, 1fr)`, lg: `repeat(4, 1fr)` },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {carouselItem?.map((item, idx) =>
          item ? (
            <Link href={item.url} passHref style={{ textDecoration: 'none' }}>
              <Card sx={(theme) => ({ maxWidth: '100%', ['&:hover']: { boxShadow: `2px 2px 2px ${theme.palette.grey[500]}` } })}
                onClick={() => { changeFilters({ [item.filter]: item.filterValue }); }}>
                <Box
                  sx={{ overflow: 'hidden' }}>
                  <Image
                    layout='fill'
                    alt={item?.name}
                    sx={{ objectFit: 'contain', transform: 'scale(1.3)' }}
                    src={item?.asset?.url ?? "/butch/static/version1673973159/adminhtml/Magento/backend/en_US/Magento_Catalog/images/product/placeholder/thumbnail.jpg" as string}
                    title={item?.name as string}
                  />
                </Box>
                <CardContent>
                  <Typography gutterBottom variant="h3" component="div">
                    {item?.name}
                  </Typography>
                  <Typography gutterBottom variant="h4" component="div">
                    {item?.price}
                  </Typography>
                  <Typography variant="body2" color="text.primary" sx={{ overflowWrap: 'break-word' }}>
                    {item?.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" variant='text' color='primary'>Shop {item?.name} {'>'}</Button>
                </CardActions>
              </Card>
            </Link>
          ) : null,
        )}
      </Box>
    </ContainerWithHeader >
  )
}
