import { IconSvg, iconStar, extendableComponent } from '@graphcommerce/next-ui'
import { Box, Chip } from '@mui/material'


const name = 'ProductReviewChipSummary' as const
const parts = ['root', 'rating', 'iconStar', 'iconStarDisabled'] as const
const { classes } = extendableComponent(name, parts)

export function ProductReviewChipSummary(props) {
  const { rating_summary, sx = [], reviewSectionId = '', max = 5, ...chipProps } = props

  if (!rating_summary) return null

  const normalizedRating = Math.round(rating_summary / (10 / max)) / 10

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const element = document.getElementById(reviewSectionId)
    e.preventDefault()
    if (!element) return

    window.scrollTo({
      top: element.offsetTop - 50,
      left: 0,
      behavior: 'smooth',
    })
  }

  if (!rating_summary) return null

  const disabledStar = (
    <IconSvg
      src={iconStar}
      size='xs'
      className={classes.iconStarDisabled}
      sx={(theme) => ({ stroke: 'none', fill: theme.palette.grey[300] })}
    />
  )

  const star = (
    <IconSvg
      src={iconStar}
      size='xs'
      className={classes.iconStar}
      sx={{ stroke: 'none', fill: '#FFDA1C' }}
    />
  )

  const icon = (
    <Box
      className={classes.root}
      sx={[
        {
          width: 'max-content',
          position: 'relative',
          '& > div': {
            lineHeight: 0,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <div>
        {disabledStar}
        {disabledStar}
        {disabledStar}
        {disabledStar}
        {disabledStar}
      </div>
      <Box
        className={classes.rating}
        style={{ width: `${rating_summary}%` }}
        sx={{
          position: 'absolute',
          top: 0,
          overflow: 'hidden',
          '& > div': { whiteSpace: 'nowrap' },
        }}
      >
        <div>
          {star}
          {star}
          {star}
          {star}
          {star}
        </div>
      </Box>
    </Box>
  )

  const chip = (
    <Chip
      component='button'
      variant='outlined'
      clickable={!!reviewSectionId}
      onClick={handleClick}
      icon={icon}
      color='default'
      size='medium'
      label={`${normalizedRating}/5`}
      {...chipProps}
    />
  )

  if (!reviewSectionId) return chip

  return chip

}
