import { ContainerProps, useTheme, useMediaQuery, Box, styled, Container } from '@mui/material'
import { m } from 'framer-motion'
import React from 'react'
import { extendableComponent } from '@graphcommerce/next-ui/Styles'
import { responsiveVal } from '@graphcommerce/next-ui/Styles/responsiveVal'
import { Row } from '@graphcommerce/next-ui/Row/Row'
import { ContainerWithHeader } from './ContainerWithHeader'

export type HeroBannerProps = ContainerProps & {
  pageLinks?: React.ReactNode
  videoSrc: string
  children: React.ReactNode
  description: string
}

const compName = 'HeroBanner' as const
const parts = ['root', 'wrapper', 'copy', 'asset', 'animated', 'video'] as const
const { classes } = extendableComponent(compName, parts)

const MotionBox = styled(m.div)({})

export function SeparateHeroBanner(props: HeroBannerProps) {
  const { pageLinks, videoSrc, children, description, ...containerProps } = props
  const t = useTheme()
  const width = "100%";
  const height = "100%";
  const matches = useMediaQuery(t.breakpoints.down('md'))
  const theme = useTheme();

  return (
    <>
      {matches &&
        <ContainerWithHeader
          title={description}
          rightArea={[]}
        >
          <MotionBox
            layout
            className={classes.root}
            sx={[
              {
                willChange: 'transform',
                display: 'grid',
                [theme.breakpoints.up('md')]: {
                  gridTemplateColumns: '1fr auto',
                },
              },
            ]}
          >
            <Row maxWidth={false} {...containerProps} className={classes.root}>
              <MotionBox
                layout
                className={classes.root}
                sx={[
                  {
                    height: height,
                    width: width,
                    willChange: 'transform',
                    display: 'grid',
                    justifyItems: 'center',
                    [theme.breakpoints.up('md')]: {
                      gridTemplateColumns: '1fr auto',
                    },
                    paddingRight: `calc((100% - ${theme.breakpoints.values.lg}px) / 4)`,
                    paddingLeft: `calc((100% - ${theme.breakpoints.values.lg}px) / 4)`,
                  },
                ]}
              >
                <MotionBox
                  layout
                  sx={[
                    {
                      willChange: 'transform',
                      position: 'relative',
                      minHeight: '50%',
                      overflow: 'hidden',
                    },
                  ]}
                >
                  <video
                    src={videoSrc}
                    autoPlay
                    muted
                    loop
                    style={{ height: "100%", width: "100%", objectFit: 'cover' }}
                    playsInline
                    disableRemotePlayback
                    className={classes.video}
                  />
                </MotionBox>
                <Box
                  className={classes.copy}
                  sx={(theme) => ({
                    marginTop: theme.spacings.lg,
                    color: theme.palette.text.primary,
                    maxWidth: '80%',
                    display: 'grid',
                    justifyItems: 'center',
                    [theme.breakpoints.up('md')]: {
                      maxWidth: '70%',
                      marginTop: 0,
                    },
                    '& > *': {
                      maxWidth: 'max-content',
                    },
                  })}
                >
                  {children}
                  {pageLinks}
                </Box>
              </MotionBox>
            </Row>
          </MotionBox>
        </ContainerWithHeader>}

      {!matches && <Row maxWidth={false} {...containerProps} className={classes.root}>
        <ContainerWithHeader
          title={description}
          rightArea={[]}
        >
          <Box
            className={classes.wrapper}
            sx={(theme) => ({
              height: height,
              width: '100%',
              display: 'flex',
              background:
                theme.palette.mode === 'light'
                  ? theme.palette.background.image
                  : theme.palette.background.paper,
              justifyItems: 'left',
              columnGap: theme.spacings.lg,
              marginTop: theme.spacings.lg,
              paddingBottom: theme.spacings.lg,
              [theme.breakpoints.up('md')]: {
                paddingTop: 0,
                paddingBottom: 0,
                background: 'none',
                gridTemplateColumns: '1fr 1fr',
              },
              borderRadius: responsiveVal(theme.shape.borderRadius * 2, theme.shape.borderRadius * 3),
            })}
          >
            <Box
              className={classes.copy}
              sx={(theme) => ({
                marginTop: theme.spacings.lg,
                color: theme.palette.text.primary,
                maxWidth: '50%',
                display: 'grid',
                alignContent: 'left',
                [theme.breakpoints.up('md')]: {
                  maxWidth: '50%',
                  marginTop: 0,
                },
                '& > *': {
                  maxWidth: 'max-content',
                },
              })}
            >
              {children}
              {pageLinks}
            </Box>
            <Box
              className={classes.asset}
              sx={(theme) => ({
                width: '50%',
                '& video': {
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                  borderRadius: responsiveVal(
                    theme.shape.borderRadius * 2,
                    theme.shape.borderRadius * 3,
                  ),
                },
              })}
            >
              <MotionBox
                style={{ width: !matches ? width : 0, height: !matches ? height : 0 }}
                className={classes.animated}
                sx={(theme) => ({
                  borderRadius: responsiveVal(
                    theme.shape.borderRadius * 2,
                    theme.shape.borderRadius * 3,
                  ),
                  overflow: 'hidden',
                  transform: 'translateZ(0)',
                })}
              >
                <video
                  src={videoSrc}
                  autoPlay
                  muted
                  loop
                  style={{
                    height: height,
                    width: width,
                    objectFit: 'cover',
                  }}
                  playsInline
                  disableRemotePlayback
                  className={classes.video}
                />
              </MotionBox>
            </Box>
          </Box>
        </ContainerWithHeader>
      </Row>}
    </>
  )
}
