import { ProductListItemProps } from '@graphcommerce/magento-product'
import { ProductListItemBundleFragment } from '@graphcommerce/magento-product-bundle/ProductListItemBundle.gql'
import { ProductListCategoryItem } from './ProductListCategoryItem'

export type ProdustListItemBundleProps = ProductListItemBundleFragment & ProductListItemProps

export function ProductListItemBundle(props: ProdustListItemBundleProps) {
  const { children } = props

  return <ProductListCategoryItem {...props}>{children}</ProductListCategoryItem>
}
