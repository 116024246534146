/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, ButtonProps } from '@mui/material'
import React, { FormEvent } from 'react'
import { extendableComponent } from '@graphcommerce/next-ui/Styles'

export type ToggleButtonProps = Omit<ButtonProps, 'onClick' | 'onChange'> & {
  selected?: boolean
  onClick?: (e: FormEvent<HTMLButtonElement>, v: any) => void
  onChange?: (e: FormEvent<HTMLButtonElement>, v: any) => void
}

type OwnerState = Pick<ButtonProps, 'size' | 'disabled'> & { selected?: boolean }

const compName = 'ToggleButton' as const
const parts = ['root', 'button', 'helperText'] as const
const { withState } = extendableComponent<OwnerState, typeof compName, typeof parts>(
  compName,
  parts,
)

export const ToggleButton = React.forwardRef<any, ToggleButtonProps>((props, ref) => {
  const {
    children,
    className,
    disabled = false,
    onChange,
    onClick,
    selected,
    size = 'medium',
    value,
    color = 'default',
    sx = [],
    ...other
  } = props
  const classes = withState({ size, selected, disabled })

  const handleChange = (event: FormEvent<HTMLButtonElement>) => onChange?.(event, value)

  const handleClick = (event: FormEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event, value)
      if (event.isDefaultPrevented()) return
    }
    handleChange(event)
  }

  return (
    <>
      {
        selected ?
          <Button
            color='primary'
            className={`${classes.root} ${className ?? ''}`}
            variant='pill'
            disabled={disabled}
            ref={ref}
            onClick={handleClick}
            onChange={handleChange}
            value={value}
            aria-pressed={selected}
            size={"small"}
            sx={{
              flex: 'none'
            }}
            {...other}
            classes={classes}
          > {
              React.Children.map(children, (child) => {
                if (!React.isValidElement(child)) return null

                return React.cloneElement(child, {
                  // @ts-ignore
                  className: `${classes.button} ${child.props.className ?? ''}`,
                  selected: selected
                })
              })
            }</Button >
          : <Button
            size='small'
            color='secondary'
            className={`${classes.root} ${className ?? ''}`}
            variant='outlined'
            disabled={disabled}
            ref={ref}
            onClick={handleClick}
            onChange={handleChange}
            value={value}
            aria-pressed={selected}
            {...other}
            classes={classes}
            sx={{
              borderRadius: '200px',
              color: 'gray',
              backgroundColor: 'transparent',
              border: '1px solid #999999',
              flex: 'none'
            }}
          >{React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) return null

            return React.cloneElement(child, {
              // @ts-ignore
              className: `${classes.button} ${child.props.className ?? ''}`,
              selected: selected
            })
          })}</Button>}
    </>
  )
})
