import { RichText } from '@graphcommerce/graphcms-ui'
import { breakpointVal, responsiveVal } from '@graphcommerce/next-ui'
import { Box, ButtonBase, Typography } from '@mui/material'
import { RowLinksFragment } from '../RowLinks.gql'
import { VariantImageLabelSwiper } from 'components/ChipLabCustom/RowLinks/VariantImageLabelSwiper'
import { Asset } from 'components/ChipLabCustom/Media/Asset'

export function ImageLabelSwiper(props: RowLinksFragment) {
  const { title, rowLinksCopy, pageLinks } = props

  return (
    <VariantImageLabelSwiper
      title={title}
      copy={rowLinksCopy && <RichText {...rowLinksCopy} />}
      sx={{ '& .Scroller-root': { alignItems: 'start' } }}
    >
      {pageLinks.map((pageLink) => (
        <ButtonBase
          href={pageLink.url}
          key={pageLink.url}
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: responsiveVal(390, 600),
            width: responsiveVal(260, 400),
            willChange: 'transform',
            borderRadius: '8px',
            '& img': { display: 'block' },
          })}
        >

          {pageLink?.asset && (
            <Asset
              asset={pageLink.asset}
              timeStamp={pageLink.buttonPriority}
              sx={{
                objectFit: 'cover',
                objectPosition: 'center',
                minHeight: responsiveVal(390, 600),
                minWidth: responsiveVal(260, 400),
              }}
            />
          )}
          <Box sx={(theme) => ({
            position: 'absolute',
            width: '100%',
            top: 32,
            backgroundColor: theme.palette.background.default,
            opacity: 0.9,
            pb: 1,
          })}>
            <Typography variant='h2' component='h2'
              sx={{
                mx: 2,
                mb: 1
              }}
            >
              {pageLink.title}
            </Typography>

            {pageLink?.description && <RichText sxRenderer={{
              'paragraph': (theme) => ({
                mx: 2,
                '& strong': (theme) => ({
                  color: theme.palette.primary.main
                }),
                ...breakpointVal('fontSize', 13, 16, theme.breakpoints.values)
              }),
            }} {...pageLink.description} />}
          </Box>
        </ButtonBase>
      ))}
    </VariantImageLabelSwiper>
  )
}
