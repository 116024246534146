import { breakpointVal, ContainerWithHeader, responsiveVal } from '@graphcommerce/next-ui'
import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material'
import { RichText } from '@graphcommerce/graphcms-ui'
import { InspirationAsset } from 'components/ChipLabCustom/Media/InpsirationAsset'

export function ImageInspiration(props) {
  const { title, rowLinksCopy, pageLinks } = props

  const theme = useTheme();
  const first = pageLinks?.length > 0 ? pageLinks?.[0] : null;

  const description = (
    <RichText
      {...rowLinksCopy}
      sxRenderer={{
        'paragraph': (theme) => ({
          ...breakpointVal('fontSize', 12, 16, theme.breakpoints.values),
          '& strong': (theme) => ({
            color: theme.palette.primary.main
          }),
        }),
      }}
    />
  );

  return (
    <Container sx={{ mb: theme.spacings.xxl }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          mb: theme.spacings.sm
        }}>
        <Typography variant='h2' sx={{ mb: theme.spacings.xs }}>{title}</Typography>
        <Typography textAlign='center'>{description}</Typography>
      </Box>
      <Box
        sx={[
          (theme) => ({
            display: 'grid',
            gridColumnGap: theme.spacings.xs,
            gridRowGap: theme.spacings.xs,
            position: 'relative',
            gridTemplateColumns: { md: '2fr 1fr' },
            height: '100%',
            [theme.breakpoints.down('md')]: {
              height: '100%'
            }
          }),
        ]}
      >
        <Box sx={{ height: responsiveVal(225, 550) + theme.spacings.xxs }}>
          <InspirationAsset
            asset={first.asset}
            sx={{
              objectFit: 'cover',
              objectPosition: 'center',
              overflow: 'hidden',
              height: '100%',
              borderRadius: '8px',
            }}
          />
        </Box>
        <Box
          sx={[
            (theme) => ({
              display: 'flex',
              gridRowGap: theme.spacings.xxs,
              justifyContent: 'space-between',
              height: '100%',
              [theme.breakpoints.down('md')]: {
                gridColumnGap: theme.spacings.xs,
                flexDirection: 'row',
              },
              flexDirection: 'column',
            }),
          ]}
        >
          {pageLinks?.map((item, idx) => {
            return item && idx > 0 ? (
              <Box
                key={item.title}
                sx={{
                  height: responsiveVal(160, 275),
                  [theme.breakpoints.down('md')]: {
                    width: '50%'
                  }
                }}
              >
                <InspirationAsset
                  asset={item.asset}
                  sx={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                    overflow: 'hidden',
                    height: '100%',
                    [theme.breakpoints.down('md')]: {
                      height: responsiveVal(160, 275),
                    },
                    borderRadius: '8px',
                  }}
                />
              </Box>
            )
              : null
          }
          )}
        </Box>
      </Box>
    </Container >
  )
}
