import {
  CustomerDocument,
  useCustomerQuery,
  useCustomerSession,
  useFormIsEmailAvailable,
} from '@graphcommerce/magento-customer'
import {
  AnimatedRow,
  Button,
  FormDiv,
  FormActions,
  FormRow,
  LayoutTitle,
  extendableComponent,
  InputCheckmark,
} from '@graphcommerce/next-ui'
import { Box, CircularProgress, Container, MenuItem, SxProps, Theme, Typography } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { graphqlErrorByCategory } from '@graphcommerce/magento-graphql'
import { useFormGqlMutation, Controller, assertFormGqlOperation, emailPattern } from '@graphcommerce/react-hook-form'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Alert, TextField } from '@mui/material'
import { ApolloCustomerErrorSnackbar } from '@graphcommerce/magento-customer/components/ApolloCustomerError/ApolloCustomerErrorSnackbar'
import { ContactUsDocument, ContactUsMutation, ContactUsMutationVariables } from 'graphql/ContactUs.gql'
import { useState } from 'react'

export type AccountSignInUpFormProps = { sx?: SxProps<Theme> }

const parts = ['root', 'titleContainer'] as const
const { classes } = extendableComponent('AccountSignInUpForm', parts)

const titleContainerSx: SxProps<Theme> = (theme) => ({
  typography: 'body1',
  marginBottom: theme.spacings.xs,
})

const requireEmailValidation = process.env.CUSTOMER_REQUIRE_EMAIL_CONFIRMATION === '1'

export function RowContact(props) {
  const { readOnly, option, email, successMessage, sx = [] } = props
  const [filename, setFileName] = useState<any>("");
  const [fileContent, setFileContent] = useState<any>("");
  const { loggedIn } = useCustomerSession();
  const { data: customerData } = useCustomerQuery(CustomerDocument);
  const Mutation = ContactUsDocument;
  const form = useFormGqlMutation<
    ContactUsMutation,
    ContactUsMutationVariables
  >(
    Mutation,
    {
      defaultValues: {
        email: loggedIn ? customerData?.customer?.email : email,
        name: loggedIn ? `${customerData?.customer?.firstname} ${customerData?.customer?.lastname}` : ""
      }, onBeforeSubmit: (values) => ({ ...values, filename, fileContent })
    },
    { errorPolicy: 'all' },
  )
  const { muiRegister, handleSubmit, required, formState, error } = form
  const [remainingError] = graphqlErrorByCategory({ category: 'graphql-input', error })
  const submitHandler = handleSubmit(() => { })
  let prefixes: Array<string> = [];

  for (let o of option) {
    prefixes.push(i18n._(o));
  }

  const { control, valid } = form
  assertFormGqlOperation(form)
  const getBase64StringFromDataURL = (dataURL) =>
    dataURL.replace('data:', '').replace(/^.+,/, '');

  const { autoSubmitting } = useFormIsEmailAvailable({ email })
  const disableFields = formState.isSubmitting && !autoSubmitting

  if (requireEmailValidation && form.formState.isSubmitSuccessful) {
    return (
      <Alert>
        <Trans id='Please check your inbox to validate your email ({email})' values={{ email }} />
      </Alert>
    )
  }

  function fileHandler(e) {
    setFileName(e.target?.files?.[0]?.name ?? "");

    var file = e.target?.files?.[0];
    var reader = new FileReader();

    if (!file)
      return;

    reader.readAsDataURL(file);
    reader.onload = function () {
      setFileContent(getBase64StringFromDataURL(reader.result));
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  return (
    <Container maxWidth='md'>
      <FormDiv sx={sx} className={classes.root}>

        <Box className={classes.titleContainer} key='signup' sx={titleContainerSx}>
          <LayoutTitle variant='h2' gutterBottom={false}>
            <Trans id='Contact' />
          </LayoutTitle>
          <Typography variant='h6' align='center'>
            <Trans id='Messages will typically have a response the next day' />
          </Typography>
        </Box>

        <AnimatePresence>

          <AnimatedRow key='signup'>
            <form onSubmit={submitHandler} noValidate>
              <FormRow>
                <Controller
                  defaultValue={prefixes[0]}
                  control={control}
                  name='problem'
                  render={({ field: { ref, onChange, ...field }, fieldState }) => (
                    <TextField
                      variant='outlined'
                      select
                      error={!!fieldState.error}
                      label={<Trans id='Problem' />}
                      required={!!required?.problem}
                      helperText={fieldState.error?.message}
                      onChange={(e) => onChange(e.target.value)}
                      inputRef={ref}
                      InputProps={{
                        readOnly,
                        endAdornment: <InputCheckmark show={valid.problem} select />,
                      }}
                      {...field}
                    >
                      {prefixes.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormRow>

              <FormRow>
                <TextField
                  variant='outlined'
                  type='text'
                  label={<Trans id='Name' />}
                  required={!!required}
                  error={!!formState.errors.name}
                  helperText={formState.isSubmitted && formState.errors.name?.message}
                  InputProps={{
                    readOnly,
                    endAdornment: <InputCheckmark show={valid.name} />,
                  }}
                  {...muiRegister('name', { required: required?.name })}
                />
              </FormRow>
              <FormRow>
                <TextField
                  key='email'
                  variant='outlined'
                  type='text'
                  autoComplete='email'
                  error={formState.isSubmitted && !!formState.errors.email}
                  helperText={""}
                  rows={4}
                  label={<Trans id='Email' />}
                  required={required.email}
                  disabled={disableFields}
                  {...muiRegister('email', {
                    required: required.email,
                    pattern: { value: emailPattern, message: '' },
                  })}
                  InputProps={{
                    endAdornment: formState.isSubmitting && <CircularProgress />,
                  }}
                />
              </FormRow>

              <FormRow>
                <TextField
                  variant='outlined'
                  type='message'
                  error={!!formState.errors.message}
                  label={<Trans id='Message' />}
                  multiline
                  {...muiRegister('message', { required: required?.message })}
                  rows={10}
                  autoComplete='Message'
                  required
                  helperText={formState.errors.message?.message}
                  disabled={formState.isSubmitting}
                />
              </FormRow>
              <FormRow>
                <input type="file" name="Attach a file" id="file" onChange={fileHandler} />
              </FormRow>

              <ApolloCustomerErrorSnackbar error={remainingError} />

              {formState.isSubmitted &&
                <Alert sx={{ margin: 'auto', alignItems: 'center', display: 'flex' }} severity="success">{successMessage}</Alert>
              }

              <FormActions>
                <Button
                  type='submit'
                  id='send-message'
                  variant='outlined'
                  color='primary'
                  size='large'
                  disabled={formState.isSubmitted}
                  loading={formState.isSubmitting}
                >
                  <Trans id='Send Message' />
                </Button>
              </FormActions>
            </form>
          </AnimatedRow>
        </AnimatePresence>
      </FormDiv>
    </Container>
  )
}
