import { ProductSpecsFragment } from '@graphcommerce/magento-product/components/ProductSpecs/ProductSpecs.gql'
import { RowHeroBannerFragment } from './RowHeroBanner.gql'
import {
  Banner,
  Video,
  CenteredBanner,
  NoImage
} from './variant'

type VariantRenderer = Record<
  NonNullable<RowHeroBannerFragment['heroVariant']>,
  React.VFC<RowHeroBannerFragment>
>

type RowProductProps = RowHeroBannerFragment & {
  renderer?: Partial<VariantRenderer>
} & ProductSpecsFragment & { items?: unknown, aggregatedReviews?: any }

const defaultRenderer: Partial<VariantRenderer> = {
  Banner,
  Video,
  CenteredBanner,
  NoImage
}

export function RowHeroBanner(props) {
  const { renderer, heroVariant, ...RowProductProps } = props
  const mergedRenderer = { ...defaultRenderer, ...renderer } as VariantRenderer

  if (!heroVariant) return null

  const RenderType =
    mergedRenderer?.[heroVariant] ??
    (() => {
      if (process.env.NODE_ENV !== 'production') return <>renderer for {heroVariant} not found</>
      return null
    })

  return <RenderType {...RowProductProps} />
}
