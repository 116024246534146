/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonProps, Fab } from '@mui/material'
import React, { FormEvent } from 'react'
import { extendableComponent } from '@graphcommerce/next-ui/Styles'

export type ToggleButtonProps = Omit<ButtonProps, 'onClick' | 'onChange'> & {
  selected?: boolean
  onClick?: (e: FormEvent<HTMLButtonElement>, v: any) => void
  onChange?: (e: FormEvent<HTMLButtonElement>, v: any) => void
  setCurrentColor?: any
  val?: any
}

type OwnerState = Pick<ButtonProps, 'size' | 'disabled'> & { selected?: boolean }

const compName = 'ToggleColorButton' as const
const parts = ['root', 'button', 'helperText'] as const
const { withState } = extendableComponent<OwnerState, typeof compName, typeof parts>(
  compName,
  parts,
)

export const ToggleColorButton = React.forwardRef<any, ToggleButtonProps>((props, ref) => {
  const {
    children,
    className,
    disabled = false,
    onChange,
    onClick,
    selected,
    size = 'medium',
    value,
    val,
    color = 'default',
    sx = [],
    setCurrentColor,
    ...other
  } = props
  const classes = withState({ size, selected, disabled })

  const handleChange = (event: FormEvent<HTMLButtonElement>) => onChange?.(event, value)

  const handleClick = (event: FormEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event, value)
      if (event.isDefaultPrevented()) return
    }
    handleChange(event)
  }

  if (selected) {
    setCurrentColor(val);
  }

  return (
    <Fab
      className={`${classes.root} ${className ?? ''}`}
      disabled={disabled}
      ref={ref}
      onClick={handleClick}
      onChange={handleChange}
      value={value}
      aria-pressed={selected}
      size={size}
      classes={classes}
      aria-label={val}
      sx={[
        (theme) => ({
          border: 1,
          borderColor: 'divider',
          bgcolor: 'background.paper',
          borderWidth: 0,
          boxShadow: 0,

          '&.disabled': {
            borderColor: "gray",
            backgroundColor: "gray",
            borderWidth: 1,
            opacity: 0.5,
          },

          '&.selected': {
            border: `1px solid ${theme.palette[color]?.main ?? theme.palette.primary.main}`,
            boxShadow: `inset 0 0 0 1px ${theme.palette[color]?.main ?? theme.palette.primary.main
              }`,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Fab>
  )
})
