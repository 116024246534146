import { breakpointVal, ContainerWithHeader, responsiveVal } from '@graphcommerce/next-ui'
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import Link from 'next/link'
import { RichText } from '@graphcommerce/graphcms-ui'
import { InspirationAsset } from 'components/ChipLabCustom/Media/InpsirationAsset'

export function Inspiration(props) {
  const { title, pageLinks, buttonName } = props

  let size = 'normal';

  const theme = useTheme();
  const inSmall = useMediaQuery(theme.breakpoints.down('md'))
  const first = pageLinks?.length > 0 ? pageLinks?.[0] : null;

  return (
    <ContainerWithHeader
      title={title}
      rightArea={null}
    >
      {!inSmall ? <Box
        sx={[
          (theme) => ({
            display: 'grid',
            gridColumnGap: theme.spacings.sm,
            gridRowGap: theme.spacings.md,
            height: '100%',
            position: 'relative',
          }),
          size === 'small' && {
            gridTemplateColumns: `repeat(auto-fill, minmax(${responsiveVal(150, 440)}, 1fr))`,
          },
          size === 'normal' && {
            gridTemplateColumns: { xs: `repeat(1, 1fr)`, sm: `repeat(1, 1fr)`, md: '1fr 2fr' },
          },
        ]}
      >
        <Box>
          <Box sx={{
            height: '100%',
            minWidth: '100%',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '8px',
            padding: 1,
          }}>
            <Box>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  overflow: 'hidden',
                  height: '234px',
                  [theme.breakpoints.up('sm')]: {
                    height: 234,
                    aspectRatio: '1fr/234'
                  },
                  borderRadius: '8px',
                  position: 'relative',
                })}>
                <InspirationAsset
                  asset={first.asset}
                  sx={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                    minHeight: responsiveVal(234, 234),
                    borderRadius: '8px',
                    overflow: 'hidden'
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                margin: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}>
              <Box>
                <Box
                  sx={(theme) => ({
                  })}>
                  <Typography gutterBottom variant="h6" component="div" sx={(theme) => ({ color: theme.palette.text.secondary })}>
                    {first?.buttonPriority}
                  </Typography>
                </Box>
                <Box
                  sx={(theme) => ({
                  })}>
                  <Typography gutterBottom variant="h3" component="div" sx={(theme) => ({ color: theme.palette.text.primary })}>
                    {first?.title}
                  </Typography>
                </Box>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  })}>
                  <RichText sxRenderer={{
                    'paragraph': (theme) => ({
                      color: theme.palette.text.secondary,
                      ...breakpointVal('fontSize', 14, 14, theme.breakpoints.values)
                    }),
                  }} raw={first?.description?.raw} />
                </Box>
              </Box>
              <Link
                href={first?.url}
                style={{ color: theme.palette.primary.main, bottom: 5, position: 'absolute' }}
              >
                Continue Reading
              </Link>
            </Box>
          </Box>
        </Box>
        <Box
          sx={[
            (theme) => ({
              display: 'grid',
              gridRowGap: theme.spacings.xs,
              height: '100%'
            }),
            size === 'small' && {
              gridTemplateRows: `repeat(auto-fill, minmax(${responsiveVal(150, 440)}, 1fr))`,
            },
            size === 'normal' && {
              gridTemplateRows: { xs: `repeat(1, 1fr)`, sm: `repeat(2, 1fr)` },
            },
          ]}
        >
          {pageLinks?.map((item, idx) => {
            return item && idx > 0 ? (
              <Box
                key={item.title}
              >
                <Box sx={{
                  height: '100%',
                  minWidth: '100%',
                  display: 'flex',
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: '8px',
                  padding: 1,
                }}>
                  <Box>
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        overflow: 'hidden',
                        height: '200px',
                        width: '320px',
                        borderRadius: '8px',
                        position: 'relative',
                      })}>
                      <InspirationAsset
                        asset={item.asset}
                        sx={{
                          objectFit: 'cover',
                          objectPosition: 'center',
                          minHeight: 200,
                          borderRadius: '8px',
                          overflow: 'hidden'
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}>
                    <Box>
                      <Box
                        sx={(theme) => ({
                        })}>
                        <Typography gutterBottom variant="h6" component="div" sx={(theme) => ({ color: theme.palette.text.secondary })}>
                          {item?.buttonPriority}
                        </Typography>
                      </Box>
                      <Box
                        sx={(theme) => ({
                        })}>
                        <Typography gutterBottom variant="h3" component="div" sx={(theme) => ({ color: theme.palette.text.primary })}>
                          {item?.title}
                        </Typography>
                      </Box>
                      <Box
                        sx={(theme) => ({
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        })}>
                        <RichText sxRenderer={{
                          'paragraph': (theme) => ({
                            color: theme.palette.text.secondary,
                            ...breakpointVal('fontSize', 14, 14, theme.breakpoints.values)
                          }),
                        }} raw={item?.description?.raw} />
                      </Box>
                    </Box>
                    <Link
                      href={item?.url}
                      style={{ color: theme.palette.primary.main }}
                    >
                      Continue Reading
                    </Link>
                  </Box>
                </Box>
              </Box>
            )
              : null
          }
          )}
        </Box>
      </Box> :
        <Box
          sx={[
            (theme) => ({
              display: 'grid',
              gridRowGap: theme.spacings.xs,
              height: '100%'
            }),
            size === 'small' && {
              gridTemplateRows: `repeat(auto-fill, minmax(${responsiveVal(150, 440)}, 1fr))`,
            },
            size === 'normal' && {
              gridTemplateRows: { xs: `repeat(1, 1fr)`, sm: `repeat(1, 1fr)` },
            },
          ]}
        >
          {pageLinks?.map((item, idx) => {
            return item ? (
              <Box
                key={item.title}>
                <Box sx={{
                  height: '100%',
                  minWidth: '100%',
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: '8px',
                  padding: 1,
                }}>
                  <Box>
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        overflow: 'hidden',
                        height: '288px',
                        borderRadius: '8px',
                        position: 'relative',
                      })}>
                      <InspirationAsset
                        asset={item.asset}
                        sx={{
                          objectFit: 'cover',
                          objectPosition: 'center',
                          minHeight: responsiveVal(300, 288),
                          maxWidth: '100%',
                          borderRadius: '8px',
                          overflow: 'hidden',
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      margin: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}>
                    <Box>
                      <Box
                        sx={(theme) => ({
                        })}>
                        <Typography gutterBottom variant="h6" component="div" sx={(theme) => ({ color: theme.palette.text.secondary })}>
                          {item?.buttonPriority}
                        </Typography>
                      </Box>
                      <Box
                        sx={(theme) => ({
                        })}>
                        <Typography gutterBottom variant="h3" component="div" sx={(theme) => ({ color: theme.palette.text.primary })}>
                          {item?.title}
                        </Typography>
                      </Box>
                      <Box
                        sx={(theme) => ({
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        })}>
                        <RichText sxRenderer={{
                          'paragraph': (theme) => ({
                            color: theme.palette.text.secondary,
                            ...breakpointVal('fontSize', 14, 14, theme.breakpoints.values)
                          }),
                        }} raw={item?.description?.raw} />
                      </Box>
                    </Box>
                    <Link
                      href={item?.url}
                      style={{ color: theme.palette.primary.main }}
                    >
                      Continue Reading
                    </Link>
                  </Box>
                </Box>
              </Box>
            )
              : null
          }
          )}
        </Box>}
      <Link href='/blog' style={{ textDecoration: 'none' }}>
        <Box sx={{
          marginTop: 3,
          justifyContent: 'center',
          display: 'flex'
        }}>
          <Button
            variant='outlined'
            color='primary'
            sx={{
              borderRadius: '50px'
            }}
          >{buttonName}</Button>
        </Box>
      </Link>
    </ContainerWithHeader >
  )
}
