import { useQuery } from '@graphcommerce/graphql'
import { ProductReviewsProps } from '@graphcommerce/magento-review'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { Row } from '@graphcommerce/next-ui'
import { Box, Typography } from '@mui/material'
import { RowProductFragment } from '../RowProduct.gql'
import { ProductReviews } from '../../../ChipLabCustom/Reviews/ProductReviews'
import { ShopByUseReviews } from 'components/ChipLabCustom/Reviews/ShopByUseReviews'
import { useState } from 'react'

type ReviewsProps = RowProductFragment & Partial<ProductReviewsProps> & { shopByUse?: boolean, setMainReviewCount?: any }

export function Reviews(props: ReviewsProps) {
  const { title, reviews, url_key, review_count, sku, shopByUse, setMainReviewCount } = props

  const { data, loading } = useQuery(StoreConfigDocument)

  const [reviewCount, setReviewCount] = useState(review_count);

  if (!reviews || loading) return null

  if (!data?.storeConfig?.product_reviews_enabled) return null

  //ok

  return (
    <Row maxWidth='md' id='reviews'>
      <Box
        sx={[
          (theme) => ({
            position: 'relative',
            '&:focus': {
              outline: 'none',
            },
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: theme.spacings.sm,
            marginBottom: theme.spacings.xxs,
            paddingBottom: theme.spacings.xxs,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }),
        ]}
      >
        <Typography variant='overline' color='textSecondary' component='h2'>
          {title} ({reviewCount})
        </Typography>
      </Box>

      {!shopByUse ? <ProductReviews
        reviews={reviews}
        url_key={url_key ?? ''}
        sku={sku}
        review_count={review_count ?? 0}
      /> :
        <ShopByUseReviews setMainReviewCount={setMainReviewCount} productIds={shopByUse} setReviewCount={setReviewCount} />
      }
    </Row>
  )
}
