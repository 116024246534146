import { useQuery } from '@graphcommerce/graphql'
import { ProductReviewsProps } from '@graphcommerce/magento-review'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { Row } from '@graphcommerce/next-ui'
import { Box, Typography } from '@mui/material'
import { MultiReviewsDocument } from '../../../../graphql/MultiReviews.gql'
import { MultiProductReviews } from '../../../ChipLabCustom/Reviews/MultiProductReviews'
import { RowProductFragment } from '../RowProduct.gql'

type ReviewsProps = RowProductFragment & Partial<ProductReviewsProps>

export function MultiReviews(props: ReviewsProps) {
  const { title, url_key, sku, uid } = props

  const { data, loading } = useQuery(StoreConfigDocument)
  let review_data;
  let data1;
  let reviews: any[] = [];
  let page_info;
  let review_count = 0;

  if (uid) {
    review_data = useQuery(MultiReviewsDocument, { variables: { UID: uid, PageSize: 5, CurrentPage: 1 } });
  }

  if (!review_data.loading) {
    data1 = review_data.data.products.items;
    page_info = { ...data1[0].reviews.page_info };
    data1.forEach((item) => { item.reviews.items.forEach((review) => { reviews.push({ name: item.name, ...review }) }) });
    review_count = reviews.length;
  }

  if (!reviews || loading) return null

  if (!data?.storeConfig?.product_reviews_enabled) return null

  return (
    <Row maxWidth='md' id='reviews'>
      <Box
        sx={[
          (theme) => ({
            position: 'relative',
            '&:focus': {
              outline: 'none',
            },
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: theme.spacings.sm,
            marginBottom: theme.spacings.xxs,
            paddingBottom: theme.spacings.xxs,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }),
        ]}
      >
        <Typography variant='overline' color='textSecondary' component='h2'>
          {title} ({review_count})
        </Typography>
      </Box>

      <MultiProductReviews
        reviews={reviews}
        page_info={page_info ?? { current_page: 1, total_pages: 1 }}
        url_key={url_key ?? ''}
        sku={sku}
        review_count={review_count ?? 0}
      />
    </Row>
  )
}
